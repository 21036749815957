.layout {
    background-color: rgba(243, 244, 248, 1);
    display: flex;
    flex-direction: row;
    padding: 0px;
    scrollbar-width: none;
}

.content-layout {
    min-height: 280px;
    scrollbar-width: none;
    padding-top: env(safe-area-inset-top, 50px);
    padding-bottom: env(safe-area-inset-bottom, 0);
    padding-left: env(safe-area-inset-left, 0);
    padding-right: env(safe-area-inset-right, 0);
}

.sider {
    background-color: rgba(255, 255, 255, 1);
}



.burger-button {
    border: none;
    padding: 7px 6px;
    background: none;
    cursor: pointer;
    fill: red;
}
.burger-button {
    border: none;
    padding: 7px 6px;
    background: none;
    cursor: pointer;
    background-color: transparent;
}
.image-style{
    width: 92px;
    height: 28.49px;
    gap: 0px;
    opacity: 0px;

}
.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 74px;
    background-color: rgba(255, 255, 255, 1);
    z-index: 1000;
    padding: 12px 16px;
}
.row-wrap3 {
    display: flex;
    gap: 1rem;
    align-items: center;
}
.circle {
    background-color: #b1b3b6;
    width: 6px;
    height: 6px;
    border-radius: 50%;
}

.active-lng {
    color: #ffffff;
    font-weight: 500;
    cursor: pointer;
}

.passive-lng {
    color: #b1b3b6;
    font-weight: 500;
    cursor: pointer;
}
@media (max-width: 768px) {

    .content-layout {
        position: fixed;
        top: 52px;
        left: 0;
        bottom: 0px;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        overflow-y: scroll;
        overflow-x: hidden;
        scrollbar-width: none;
    }
    .mobile-header {
        background-color: rgba(16, 14, 113, 1);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: env(safe-area-inset-top) 14px 0 12px;
        border-bottom: 1px solid #e8e8e8;
        overflow-y: scroll;
        overflow-x: hidden;
        scrollbar-width: none;
        width: 100%;
        position: fixed;
        top: 0px;
        left: 0;
        z-index: 1000;
    }
}
