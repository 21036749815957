.input-style{
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    padding: 12px 24px;
}
.input-style::placeholder {
    font-size: 14px;
    white-space: nowrap;
}
.input-style::-webkit-input-placeholder {
    font-size: 14px;
    white-space: nowrap;
}
.input-style:-moz-placeholder {
    font-size: 14px;
    white-space: nowrap;
}
.input-style:-ms-input-placeholder {
    font-size: 14px;
    white-space: nowrap;
}
.input-style::-ms-input-placeholder {
    font-size: 14px;
    white-space: nowrap;
}
.selector-style{
    height: 48px;
}
.button-style{
    background-color: #100E71;
    width: 100%;
    color: white;
    height: 56px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    border-radius: 12px;
}