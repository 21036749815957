.container {
    width: 75vw;
    padding-left: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-bottom: 2rem;
}

.title {
    color: #100e71;
    font-size: 34px;
    margin: 0;
}
.title2 {
    color: #100e71;
    font-size: 20px;
    margin: 0;
}

.tabs {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 1rem;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    width: 50vw;
}

.selectedTab {
    background-color: #100e71;
    border-radius: 6px;
    padding: 0.5rem;
    text-align: center;
    cursor: pointer;
}

.tab {
    background-color: #9b9ba0;
    border-radius: 6px;
    padding: 0.5rem;
    text-align: center;
    cursor: pointer;
}

.card {
    background-color: #fff;
    border-radius: 12px;
    color: #100e71;
    padding: 1rem;
}

.primary-button {
    border: none;
    border-radius: 12px;
    background-color: #100e71;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    padding: 0.5rem;
    width: 30%;
}

.secondary-button {
    border: none;
    border-radius: 12px;
    background-color: #fff;
    color: #100e71;
    font-size: 16px;
    cursor: pointer;
    padding: 0.5rem;
}

.cancel-button {
    border: 1px solid #fff;
    border-radius: 12px;
    background-color: #100e71;
    color: #fff;
    font-size: 16px;
    padding: 0.5rem;
    cursor: pointer;
}

.grid {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 1rem;
}

.withTop {
    padding-top: 1rem;
}

.fullWidth {
    width: 100%;
}

.select-parameter {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    background-color: #f3f4f8;
    width: 100%;
    padding: 12px 24px 12px 24px;
    display: flex;
    border-radius: 8px;
    justify-content: space-between;
    cursor: pointer;
}

.buttons-wrap {
    display: grid;
    gap: 1rem;
    width: 100%;
    align-items: center;
    margin-bottom: 1rem;
    grid-template-columns: 50% 50%;
}

.list {
    list-style: none;
    background-color: #f3f4f8;
    border-radius: 6px;
    padding: 1rem;
    max-height: 400px;
    overflow: auto;
    gap: 6px;
    display: flex;
    flex-direction: column;
}

.list-item {
    border: 1px solid #100e71;
    background-color: #f3f4f8;
    color: #100e71;
    border-radius: 8px;
    padding: 0.5rem;
    cursor: pointer;
}

.selected-item {
    border: 1px solid #100e71;
    background-color: #100e71;
    color: #f3f4f8;
    border-radius: 8px;
    padding: 0.5rem;
    cursor: pointer;
}
