@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

.container {
    width: 100vw;
    margin: 0 auto;
    box-sizing: border-box;
}
.history-status-div{
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
}
.header {
    max-width: 100% !important;
    height: 278px;
    background: #FFF;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.04);
}

.header-div {
    padding: 52px 0 0 44px;
    display: flex;
    flex-direction: row;
    gap: 80px;
    align-items: start;
}

.enter-style {
    border: none;
    background: transparent;
    cursor: pointer;
}

.header-text-id {
    margin: 0;
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}

.header-text-order {
    margin-top: 13px;
    color: #1A1C1F;
    font-family: 'Roboto', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 45px;
}

.header-text-new {
    display: inline-block;
    border-radius: 8px;
    border: 1px solid #797B80;
    background: #EAECF0;
    padding: 5.5px 16px;
    color: #797B80;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 128%;
    margin-top: 24px;
}

.content {
    margin: 20px 0 200px 158px;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.order-info {
    width: 582px;
    padding: 24px;
    background: white;
    border-radius: 24px;
}

.title-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.title-text {
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.title-button {
    color: #100E71;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;
}

.all-info {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.order-details {
    display: flex;
    width: 100%;
    align-items: center;
}

.label {
    flex: 1;
    white-space: nowrap;
    position: relative;
    margin-right: 8px;
    color: #797B80;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.dots {
    flex-grow: 8;
    border-bottom: 1px dotted #797B80;
    margin: 0 8px;
}

.value {
    width: 50%;
    white-space: nowrap;
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.all-info {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.tarif-div {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.price-div {
    gap: 13.5px;
    margin-right: 158.27px;
}

.tariff-style {
    color: #797B80;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.tariff-price {
    margin: 0;
    color: #100E71;
    font-family: 'Roboto', sans-serif;
    font-size: 25.695px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.52px;
}

.tariff-style-text {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    color: #100E71;
    text-align: center;
    font-size: 12px;
    padding: 5.5px 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 128%;
    border-radius: 8px;
    background: #CAE0FF;
}

.line-div {
    width: 1px;
    height: 57.5px;
    background-color: rgba(121, 123, 128, 1);
}

.change-button {
    border: none;
    border-radius: 12px;
    background: rgba(177, 177, 170, 0.40);
    width: 123px;
    height: 46px;
    color: #100E71;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.change-div {
    margin-top: 24px;
}

.change-button:hover {
    background: #100E71;
    color: white;
}

.history-div {
    width: 429px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.04);
}

.title-history-style {
    margin: 0;
    color: #1A1C1F;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.history-text-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.date-div {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.date-day {
    margin: 0;
    color: #1A1C1F;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}

.date-time {
    margin: 0;
    color: #666C72;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.history-status {
    display: inline-block;
    padding: 5.5px 16px;
    margin: 0;
    color: black;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 128%;
    border-radius: 8px;
    background: white;
}

.modal-div {
    display: flex;
    flex-direction: column;
}

.input-div {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.modal-title {
    margin: 0;
    color: #FFF;
    font-family: 'Roboto', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px;
}

.input-modal {
    padding: 12px 24px;
    border-radius: 8px;
    background: #FFF;
    color: #100E71;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.modal-button {
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin-top: 40px;
}

.button-modal {
    width: 100%;
    height: 56px;
    border-radius: 12px;
    border: 2px solid #FFF;
    color: #FFF;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    background: #100E71;
}

.button-modal:hover {
    background: #FFF;
    color: #100E71;
}
.input-container {
    position: relative;
    margin-bottom: 0px;
    width: 100%;
}
.input-modal {
    width: 100%;
    border-radius: 8px;
    padding: 24px 24px 4px;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: rgba(16, 14, 113, 1);
    position: relative;
}

.modal-button {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    gap: 12px;
}
.floating-label {
    position: absolute;
    top: 50%;
    left: 24px;
    transform: translateY(-50%);
    transition: all 0.2s ease-in-out;
    color: rgba(102, 108, 114, 1);
    pointer-events: none;
    font-size: 16px;
    line-height: 24px;
}

.input-modal:active + .floating-label:active,
.filled {
    padding: 0 0 35px;
    font-size: 16px;
    color: rgba(16, 14, 113, 1) ;
}
@media (max-width: 768px) {
    .container {
        width: 100vw;
        margin: 0 auto;
        box-sizing: border-box;
    }

    .header {
        margin-top: 56px;
        max-width: 100% !important;
        height: auto;
        background: #FFF;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.04);
    }

    .header-div {
        padding: 12px 16px 0 16px;
        display: flex;
        flex-direction: row;
        gap: 80px;
        justify-content: space-between;
        align-items: start;
    }

    .enter-style {
        border: none;
        background: transparent;
        cursor: pointer;
    }

    .header-text {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
    }

    .header-text-id {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
    }

    .header-text-order {
        margin-top: 15px;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
    }

    .header-text-new {
        margin-top: 16px;
    }

    .content {
        margin: 24px 12px 12px 12px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-bottom: 100px;
    }

    .order-info {
        width: 100%;
        padding: 16px 12px;
        background: white;
        border-radius: 8px;
    }

    .title-div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        gap: 12px;
    }

    .title-button {
    }

    .all-info {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .order-details {
        display: flex;
        width: 100%;
        align-items: center;
    }

    .label {
        flex: 1;
        white-space: nowrap;
        position: relative;
        margin-right: 8px;
        color: #797B80;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
    }

    .dots {
        flex-grow: 8;
        border-bottom: 1px dotted #797B80;
        margin: 0 8px;
    }

    .value {
        width: 50%;
        white-space: wrap;
        color: #000;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
    }

    .all-info {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .tarif-div {
        margin-top: 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
    }

    .price-div {
        display: flex;
        flex-direction: column;
        gap: 13.5px;
        margin-right: 10%;
    }

    .price-div-tarif {
        display: flex;
        flex-direction: column;
        gap: 13.5px;
    }

    .tariff-style {
        color: #797B80;
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }

    .tariff-price {
        margin: 0;
        color: #100E71;
        font-family: 'Roboto', sans-serif;
        font-size: 25.695px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: -0.52px;
    }

    .tariff-style-text {
        margin: 0;
        font-family: 'Roboto', sans-serif;
        color: #100E71;
        text-align: center;
        font-size: 12px;
        padding: 5.5px 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 128%;
        border-radius: 8px;
        background: #CAE0FF;
    }

    .line-div {
        width: 1px;
        height: 57.5px;
        background-color: rgba(121, 123, 128, 1);
    }

    .change-button {
        border: none;
        border-radius: 12px;
        background: rgba(177, 177, 170, 0.40);
        width: 123px;
        height: 46px;
        color: #100E71;
        text-align: center;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    .change-div {
        margin-top: 24px;
    }

    .change-button:hover {
        background: #100E71;
        color: white;
    }

    .history-div {
        width: 100%;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        background-color: white;
        border-radius: 12px;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.04);
    }

    .title-history-style {
        margin: 0;
        color: #1A1C1F;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }

    .history-text-div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .date-div {
        display: flex;
        flex-direction: column;
        gap: 3px;
    }

    .date-day {
        margin: 0;
        color: #1A1C1F;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
    }

    .date-time {
        margin: 0;
        color: #666C72;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
    }

    .history-status {
        display: inline-block;
        padding: 5.5px 16px;
        margin: 0;
        color: black;
        text-align: center;
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 128%;
        border-radius: 8px;
    }

    .modal-div {
        display: flex;
        flex-direction: column;
    }

    .input-div {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .modal-title {
        margin: 0;
        color: #FFF;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
    }

    .input-modal {
        padding: 16px 24px 8px;
        border-radius: 8px;
        background: #FFF;
        color: #666C72;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }
    .input-modal:active + .floating-label:active,
    .filled {
        padding: 0 0 30px;
        font-size: 16px;
        color: rgba(16, 14, 113, 1) ;
    }

    .modal-button {
        display: flex;
        flex-direction: row;
        gap: 12px;
        margin-top: 32px;
    }

    .button-modal {
        width: 100%;
        height: 44px;
        border-radius: 12px;
        border: none;
        text-align: center;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        background: #FFF;
        color: #100E71;
    }

    .button-modal:hover {
        background: #100E71;
        color: #FFF;
    }
}
