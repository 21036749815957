.customSelect {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 70px;
    align-items: center;
    text-align: center;
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 8px;
    background-color: white;
    color: #333;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    color: rgba(16, 14, 113, 1);
    border-color: rgba(16, 14, 113, 1);
    background-color: white;
}

.customSelect:hover {
    color: white;
    border-color: white;
    background-color: rgba(16, 14, 113, 1);
    outline: none;
}

.customSelect option {
    text-align: center;
    padding: 10px;
}
@media (max-width: 768px) {
    .customSelect {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 70px;
        align-items: center;
        text-align: center;
        padding: 10px;
        border: 2px solid #ccc;
        border-radius: 8px;
        background-color: white;
        color: #333;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        cursor: pointer;
        font-size: 12px;
        font-family: "Roboto", sans-serif;
        font-weight: 600;
        color: rgba(16, 14, 113, 1);
        border-color: rgba(16, 14, 113, 1);
        background-color: white;
    }

    .customSelect:hover {
        color: white;
        border-color: white;
        background-color: rgba(16, 14, 113, 1);
        outline: none;
    }

    .customSelect option {
        text-align: center;
        padding: 10px;
    }
}
