.outer-wrap {
    display: flex;
    justify-content: center;
    margin-top: 10%;
}

.register-container {
    background-color: rgba(16, 14, 113, 1);
    height: fit-content;
    border-radius: 40px;
    padding: 56px;
    width: 514px;
}

.login-button-group {
    display: flex;
    gap: 20px;
}

.register-container-images {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    width: 100%;
    justify-content: space-between;
}
.go-back {
    border: none;
    background-color: transparent;
    align-self: center;
}

.register-container-images-delivery {
    position: absolute;
    z-index: 5;
    right: -175px;
    top: -95px;
}

.register-input {
    height: 35px;
}
.register-input::placeholder {
    color: #666c72;
}
.image-logo {
    display: flex;
    flex-direction: row;
    align-self: center;
}

.forgetPassword {
    color: #fff;
    font-weight: 500;
    margin-top: 1.5rem;
    cursor: pointer;
}
.go-back-main {
    background-color: transparent;
    border: none;
    font-size: 18px;
    color: white;
    font-family: "Roboto", sans-serif;
}
@media screen and (max-width: 768px) {

    html,
    body {
        overflow-x: hidden;
        overflow-y: auto;
        height: 100%;
        margin: 0;
    }
    .outer-wrap {
        display: flex;
        justify-content: center;
        margin-top: 0%;
    }
    .register-container {
        zoom: 100%;
        border-radius: 0;
        margin: 0;
        padding: 56px 24px 2000px;
        touch-action: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        overflow-y: auto;  /* Включает вертикальную прокрутку в контейнере */
    }
    .register-container-images-delivery {
        display: none;
    }

    .login-button-group {
        display: block;
    }
    .image-logo {
        display: none;
        flex-direction: row;
        align-self: center;
        justify-content: center;
    }
    .register-container-images {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-self: center;
        width: 100%;
        justify-content: space-between;
        position: relative;
    }
}
