.container {
    width: 100%;
    margin: 0 auto;
}
.notification {
    position: fixed;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #100e71;
    color: white;
    padding: 10px;
    border-radius: 8px;
    z-index: 1000;
    font-size: 42px;
}

.title {
    margin-top: 44px;
}

.barcode-input {
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin-top: 25px;
}

.content {
    margin: 0 53px;
    overflow-y: auto;
}

.date-picker-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    width: 100%;
}

.react-datepicker {
    background-color: white;
    border: 1px solid rgba(16, 14, 113, 1);
    font-size: 15.4px;
    padding: 5px 15px;
    border-radius: 8px;
}

.react-datepicker__header {
    background-color: #4caf50;
    color: red;
    border-bottom: none;
    border-radius: 5px 5px 0 0;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
    background-color: #4caf50 !important;
    color: white !important;
    border-radius: 50% !important;
}

.react-datepicker__day--today {
    font-weight: bold;
    color: #4caf50;
}

.react-datepicker__day:hover {
    background-color: #f0f0f0;
    border-radius: 50%;
}

.react-datepicker__day-name,
.react-datepicker__current-month {
    color: white;
}

.react-datepicker__navigation--previous {
    border-right-color: white;
}

.react-datepicker__navigation--next {
    border-left-color: white;
}

.selecter-div {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}

.style-text {
    font-weight: 600;
    align-items: center;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    color: rgba(59, 60, 90, 1);
    line-height: 19.32px;
}

.title-text {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 44px;
    line-height: 55px;
    color: rgba(16, 14, 113, 1);
}

.input-style {
    align-items: center;
    border: 1px none;
    width: 100%;
    height: 52px;
    padding: 6px 20px;
    border-radius: 24px;
    font-family: Inter, sans-serif;
    font-size: 16px;
    color: rgba(16, 14, 113, 1);
    background-image: url('../../assets/lupa.png');
    background-repeat: no-repeat;
    background-position: 20px 18px;
    padding-left: 52px;
    background-size: 16px 16px;
}

.input-style::before {
    content: '';
    background-image: url('../../assets/icon-telefone.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

::placeholder {
    color: rgba(16, 14, 113, 1);
}

.input {
    display: flex;
    flex-direction: row;
    margin-top: 24px;
    gap: 12px;
}

.button {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    font-family: Inter, sans-serif;
    color: rgba(59, 60, 90, 1);
    fill: rgba(59, 60, 90, 1);
    line-height: 19.32px;
    border: none;
    border-radius: 8px;
    height: 40px;
    padding: 10px;
    width: 166px;
    border-radius: 10.5px;
    background-color: white;
}

.button:hover {
    color: white;
    fill: white;
    background-color: rgba(16, 14, 113, 1);
}

.button-div {
    margin-top: 24px;
}

.order-div {
    margin-top: 60px;
}

.order-create {
    margin-top: 24px;
    display: flex;
    gap: 12px;
    flex-direction: row;
    flex-wrap: wrap;
}

.button-filter {
    display: inline-block;
    font-family: Inter, sans-serif;
    font-size: 16px;
    line-height: 19.32px;
    color: rgba(59, 60, 90, 1);
    font-weight: 600;
    background-color: white;
    border-radius: 8px;
    border: none;
    flex-wrap: wrap;
    padding: 10.5px 10px;
    white-space: nowrap;
}

.button-filter:hover {
    color: white;
    background-color: rgba(16, 14, 113, 1);
}

.found-button {
    margin-left: 8px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 18px;
    color: rgba(255, 255, 255, 1);
    border-radius: 24px;
    padding: 9px 2px;
    border: none;
    width: 190px;
    background-color: rgba(95, 173, 5, 1);
    cursor: pointer;
}

.div-change-button {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: white;
    border-radius: 24px;
}

.button-change {
    width: 100%;
    height: 60px;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    border-radius: 24px;
    border: none;
    color: #100e71;
    background-color: white;
    transition:
        background-color 0.5s ease,
        color 0.5s ease;
}
.filter-button-new {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #3b3c5a;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    gap: 10px;
    padding: 10px;
    border-radius: 8px;
    background: #fff;
    border: none;
    fill: #3b3c5a;
    cursor: pointer;
}
.filter-button-new:hover {
    color: white;
    background: rgba(16, 14, 113, 1);
    fill: white;
}
.filter-button-new:focus {
    color: white;
    background: rgba(16, 14, 113, 1);
    fill: white;
}
.filter-button-new:active {
    color: white;
    background: rgba(16, 14, 113, 1);
    fill: white;
}
.title-div{
    display: flex;
    flex-direction: row;
    gap: 20px
}
.title {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}
.title-div-text{
    font-size: 24px;
    font-weight: 600;
    color: rgba(16, 14, 113, 1)
}

@media (max-width: 768px) {
    .found-button {
        align-items: center;
        align-self: center;
        font-family: 'Roboto', sans-serif;
        font-size: 4.38vw;
        line-height: 5.63vw;
        color: rgba(255, 255, 255, 1);
        border-radius: 7.5vw;
        padding: 2.81vw 0.63vw;
        border: none;
        width: 59.38vw;
        background-color: rgba(95, 173, 5, 1);
        cursor: pointer;
    }
}
.button-text {
    width: 172px;
    color: #fff;
    margin: 0;
    height: 52px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    border-radius: 12px;
    background: #100e71;
    border: none;
}

.button-text:hover {
    color: #100e71;
    background: white;
}

@media (max-width: 768px) {
    .container {
        height: 100%;
        width: 100vw;
        margin: 0 auto;
    }
    .content {
        margin: 16px 12px 0;
    }
    .title {
        margin-top: 76px;
    }
    .title-text {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 55px;
    }
    .input-style {
        align-items: center;
        border: 1px none;
        width: 100%;
        height: 46px;
        padding: 11px 56px;
        color: #100e71;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        background-image: url('../../assets/lupa.png');
        background-repeat: no-repeat;
        background-position: 24px 15px;
        background-size: 16px 16px;
        padding-left: 56px;
        position: relative;
    }
    .input-style::before {
        content: '';
        background-image: url('../../assets/icon-telefone.png');
        background-repeat: no-repeat;
        background-size: contain;
        width: 57.6px;
        height: 57.6px;
        margin-right: 19.2px;
    }
    ::placeholder {
        color: rgba(16, 14, 113, 1);
    }
    .input {
        gap: 12px;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 24px;
    }
    .button-div {
        margin-top: 57.6px;
    }
    .order-div {
        margin-top: 57.6px;
    }
    .order-create-button {
        font-family: 'Roboto', sans-serif;
        font-size: 33.6px;
        line-height: 43.2px;
        color: rgba(255, 255, 255, 1);
        border-radius: 57.6px;
        padding: 21.6px 4.8px;
        border: none;
        width: 456px;
        background-color: rgba(95, 173, 5, 1);
    }
    .order-create {
        margin-top: 24px;
        width: 100%;
        display: flex;
        gap: 10px;
        flex-direction: row;
    }
    .button-filter {
        font-family: 'Inter', sans-serif;
        font-size: 13px;
        line-height: 24.32px;
        padding: 5.5px 12px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .button-filter:hover {
        color: white;
        background-color: rgba(16, 14, 113, 1);
    }
    .found-button {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #fff;
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        border-radius: 8px;
        background: #100e71;
        padding: 0 4.8px;
        height: 36px;
        width: 177px;
        cursor: pointer;
    }
    .date-picker-container {
        margin-top: 12px;
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: center;
        width: 100%;
    }
    .react-datepicker {
        background-color: white;
        border: 1px solid rgba(16, 14, 113, 1);
        font-size: 10px;
        padding: 10px 10px;
        border-radius: 12px;
    }
    .react-datepicker__header {
        background-color: #4caf50;
        color: red;
        border-bottom: none;
        border-radius: 5px 5px 0 0;
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected {
        background-color: #4caf50 !important;
        color: white !important;
        border-radius: 50% !important;
    }
    .react-datepicker__day--today {
        font-weight: bold;
        color: #4caf50;
    }
    .react-datepicker__day:hover {
        background-color: #f0f0f0;
        border-radius: 50%;
    }
    .react-datepicker__day-name,
    .react-datepicker__current-month {
        color: white;
    }
    .react-datepicker__navigation--previous {
        border-right-color: white;
    }
    .react-datepicker__navigation--next {
        border-left-color: white;
    }
    .style-text {
        flex-wrap: nowrap;
        font-weight: 400;
        align-items: center;
        font-size: 11px;
        font-family: 'Roboto', sans-serif;
        color: rgba(59, 60, 90, 1);
        line-height: 19.32px;
    }
    .div-change-button {
        margin-top: 6px;
        display: flex;
        flex-direction: row;
        width: 100%;
        background-color: white;
        border-radius: 24px;
    }
    .button-change {
        align-items: center;
        align-self: center;
        font-family: 'Roboto', sans-serif;
        padding: 7px;
        width: 100%;
        height: 39px;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: -0.15px;
        text-align: center;
        border-radius: 24px;
        border: none;
        background-color: white;
    }
    .button-text {
        align-items: center;
        align-self: center;
        padding: 7px;
        width: 172px;
        height: 39px;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: -0.15px;
        text-align: center;
        border: none;
        background: #100e71;
    }
}
