.btn-style{
    margin-top: 8px;
    width: 100%;
    height: 52px;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    align-self: center;
    text-align: center;
    line-height: 24px;
    text-align: center;
    color: #100E71;
    background-color: white;

}
.logo-div{
    margin-top: 0px;
}
.title-text{
    margin: 0;
    margin-top: 32px;
    color: #FFF;
    font-family: 'Roboto', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px;
    text-align: center;
}
.btn-style:hover{
    background-color: #100E71;
    color: white;
    border: 1px solid white;

}
.client-error {
    position: fixed;
    top: 20px;
    left: 50%;
    font-family: 'Roboto', sans-serif;
    transform: translateX(-50%);
    background-color: #100E71;
    color: white;
    padding: 10px 20px;
    border-radius: 8px;
    z-index: 1000;
    font-size: 16px;
    opacity: 0.9;
}

.register-input {
    width: 100%;
    padding: 12px 24px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    border-radius: 8px;
}
.register-input::placeholder {
    color: rgba(102, 108, 114, 1);
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}
.tel-style {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.15000000596046448px;
    text-align: left;
    color: white;
    margin-top: 24px;
}
.number-style {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    padding-left: 5%;
    color: white;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;

}
@media(max-width: 768px) {
    .title-text{
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 125% */
    }
    .client-error {
        position: fixed;
        top: 50px;
        left: 50%;
        font-family: 'Roboto', sans-serif;
        transform: translateX(-50%);
        background-color: white ;
        color: #100E71;
        font-weight: 600;
        padding: 10px 20px;
        border-radius: 8px;
        z-index: 1000;
        font-size: 16px;
        opacity: 0.9;
    }
}