.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.button-pages {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    color: black;
    background-color: white;
    min-width: 32px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid rgba(223, 227, 232, 1);
    margin: 0 2px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-pages:hover {
    background-color: rgba(16, 14, 113, 1);
    color: white;
}

.button-pages:focus, .button-pages.active {
    background-color: rgba(16, 14, 113, 1);
    color: white;
}

.button-pages:disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

.disabled {
    cursor: not-allowed;
    opacity: 0.6;
}
