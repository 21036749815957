.input-wrap {
    position: relative;
}

.input-label {
    position: absolute;
    left: 1.5rem;
    font-size: 12px;
    color: #666c72;
}

.input-style {
    border-radius: 8px;
    padding: 16px 24px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #1a1c1f;
    width: 100%;
    border: none;
    background-color: #f3f4f8;
    margin-bottom: 0.5rem;
}

.hint {
    position: absolute;
    font-size: 18px;
    left: 3.5rem;
    top: 1.125rem;
    color: #000000;
}
