.container {
    margin: 0 auto;
    width: 100%;
    max-width: 1031px;
}
.content {
    margin-top: 24px;
    width: 100%;
    padding: 0 15px;
}
.input-div-input {
    width: 100%;
    display: flex;
    flex-direction: row;
}
.input-style {
    align-items: center;
    border: 1px none;
    width: 100%;
    height: 40px;
    padding: 6px 20px;
    border-radius: 24px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    color: rgba(16, 14, 113, 1);
    background-image: url("../../../assets/lupa.png");
    background-repeat: no-repeat;
    background-position: 20px 12px;
    padding-left: 52px;
    background-size: 16px 16px;
}
.found-button {
    margin-left: 8px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 18px;
    color: rgba(255, 255, 255, 1);
    border-radius: 24px;
    padding: 9px 2px;
    border: none;
    width: 190px;
    background-color: rgba(95, 173, 5, 1);
    cursor: pointer;
}
.donwload-div {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    margin-top: 24px;
}
.donwload-button {
    gap: 10px;
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    border: none;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: rgba(16, 14, 113, 1);
    background-color: transparent;
}
::placeholder {
    color: rgba(16, 14, 113, 1);
}
.table-order {
    margin-top: 40px;
    width: 100%;
    padding: 24px;
    border-radius: 8px;
    border: 1px solid #F4F4F4;
    background: #FFF;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}
.modal-div {
    width: 100%;
}
.div-pages {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 24px;
}
.button-pages {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    color: rgba(102, 108, 114, 1);
    background-color: white;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid rgba(223, 227, 232, 1);
}
.button-pages:hover {
    border: 1px solid rgba(16, 14, 113, 1);
    color: rgba(16, 14, 113, 1);
}
.button-pages:focus {
    border: 1px solid rgba(16, 14, 113, 1);
    color: rgba(16, 14, 113, 1);
}
.modal-title {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 45px;
    color: white;
}
.input-div {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.text-input {
    margin: 0;
    margin-bottom: -4px;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
}
.input-modal {
    border-radius: 8px;
    padding: 16px 24px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: rgba(102, 108, 114, 1);
}
.modal-button {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    gap: 12px;
}
.button-modal {
    width: 100%;
    padding: 16px 0;
    border-radius: 12px;
    border: 2px solid white;
    background-color: white;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: rgba(16, 14, 113, 1);
}
.button-modal:hover {
    background-color: rgba(16, 14, 113, 1);
    color: white;
}
.table {
    background: #FFF;
    padding: 24px;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid rgba(16, 14, 113, 1);
}
.th-style:first-child {
    padding: 28px 5px;
    margin: 0;
    width: 350px !important;
    color: #666C72;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}
.th-style {
    padding: 25px 5px;
    color: #666C72;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    border: 1px solid rgba(16, 14, 113, 1);
}
.th-style:last-child {
    padding: 25px 5px;
    width: 90px;
    color: #666C72;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    border: 1px solid rgba(16, 14, 113, 1);
}
.td-style {
    border: 1px solid rgba(16, 14, 113, 1);
    padding: 23px 5px;
    text-align: center;
    width: 100px;
    color: #666C72;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}
.status-waiting {
    color: rgba(132, 70, 8, 1);
    border-radius: 8px;
    border: 1px solid #894705;
    background: #FFBD7B;
    padding: 4px 8px;
}
.status-postomat {
    color: rgba(54, 89, 15, 1);
    border-radius: 8px;
    border: 1px solid #36590F;
    background: #E2FBC5;
    padding: 4px 8px;
}
.status-lost {
    color: rgba(178, 56, 19, 1);
    border-radius: 8px;
    border: 1px solid #B23813;
    background: #FB8484;
    padding: 4px 8px;
}
.status-cargo {
    color: rgba(16, 14, 113, 1);
    border-radius: 8px;
    border: 1px solid #100E71;
    background: #CAE0FF;
    padding: 4px 8px;
}
.button-div-table {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.button-table {
    width: 67px;
    padding: 2px 0;
    font-family: 'Roboto', sans-serif;
    border: 1px solid rgba(16, 14, 113, 1);
    font-size: 11px;
    font-weight: 400;
    line-height: 18px;
    border-radius: 4px;
    background-color: rgba(16, 14, 113, 1);
    color: white;
}
.button-table:hover {
    background-color: rgba(16, 14, 113, 1);
    color: white;
    cursor: pointer;
}
.mobile-view {
    display: none;
}
