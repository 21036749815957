
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

.table-order {
    margin-top: 24px;
    width: 100%;
    padding: 0;
    background: #FFF;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.select-status {
    font-family: 'Roboto', sans-serif;
    width: 100%;
    font-size: 12px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
}

.option-status {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
}

.modal-div {
    width: 100%;
}
.modal-div-barcode {
    display: flex;
    flex-direction: column;
    gap:40px;
    align-items: center;
}
.image-style-barcode{
    padding: 20px 40px;
    border-radius: 20px;
    background-color: white;
}
.div-pages {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 24px;
}

.button-pages {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    color: rgba(102, 108, 114, 1);
    background-color: white;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid rgba(223, 227, 232, 1);
}

.button-pages:hover {
    border: 1px solid rgba(16, 14, 113, 1);
    color: rgba(16, 14, 113, 1);
}

.button-pages:focus {
    border: 1px solid rgba(16, 14, 113, 1);
    color: rgba(16, 14, 113, 1);
}

.modal-title {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 32px;
    font-weight: 400;
    line-height: 45px;
    color: white;
}

.input-div {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.text-input {
    margin: 0;
    margin-bottom: -4px;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
}

.input-modal {
    border-radius: 8px;
    padding: 10px 16px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: rgba(102, 108, 114, 1);
}

.modal-button {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    gap: 12px;
}
.button-modal-barcode{
    width: 300px;
    padding: 16px 0;
    border-radius: 12px;
    border: 2px solid white;
    background-color: rgba(16, 14, 113, 1);
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: white;
}
.button-modal-barcode:hover {
    background-color: rgba(16, 14, 113, 1);
    color: white;
}
.button-modal {
    width: 100%;
    padding: 10px 0;
    border-radius: 12px;
    border: 2px solid white;
    background-color: white;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: rgba(16, 14, 113, 1);
}

.button-modal:hover {
    background-color: rgba(16, 14, 113, 1);
    color: white;
}

.table {
    table-layout: fixed;
    background: rgba(255, 255, 255, 1);
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
}

.th-style:first-child {
    word-wrap: break-word;
    padding: 28px 0px;
    margin: 0;
    width: 50px !important;
    color: #666C72;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.th-style {
    word-wrap: break-word;
    padding: 25px 5px;
    width: 80px !important;
    color: #666C72;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    border: 1px solid rgba(16, 14, 113, 1);
    border-bottom: 4px solid rgba(16, 14, 113, 1);
}
.tbody:nth-child(even) {
    background-color: rgba(243, 244, 248, 1);
    border: none;
}
.tbody:nth-child(even) {
    background-color: rgba(243, 244, 248, 1);
    border: none;
}

.td-style {
    word-wrap: break-word;
    height: auto;
    word-wrap: break-word;
    border: 1px solid rgba(16, 14, 113, 1);
    padding: 28px 5px;
    text-align: center;
    color: #666C72;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.status-waiting {
    color: rgba(132, 70, 8, 1);
    border-radius: 8px;
    border: 1px solid #894705;
    background: #FFBD7B;
    padding: 4px 8px;
}

.status-postomat {
    color: rgba(54, 89, 15, 1);
    border-radius: 8px;
    border: 1px solid #36590F;
    background: #E2FBC5;
    padding: 4px 8px;
}

.status-lost {
    color: rgba(178, 56, 19, 1);
    border-radius: 8px;
    border: 1px solid #B23813;
    background: #FB8484;
    padding: 4px 8px;
}

.status-cargo {
    color: rgba(16, 14, 113, 1);
    border-radius: 8px;
    border: 1px solid #100E71;
    background: #CAE0FF;
    padding: 4px 8px;
}

.button-div-table {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.button-table {
    align-self: center;
    align-items: center;
    text-align: center;
    padding: 2px 0;
    width: 100%;
    height: auto;
    color:  #100E71;
    text-align: center;
    font-family: 'Roboto', bold;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    border-radius: 8px;
    background: rgba(177, 177, 170, 0.40);
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.button-table:hover {
    background-color: rgba(16, 14, 113, 1);
    color: white;
}

.mobile-view {
    display: none;
}
.status-color-name {
    display: inline-block;
    padding: 5.5px 16px;
    border-radius: 8px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    overflow: hidden;
    word-wrap: break-word;
    max-width: 100%;
    box-sizing: border-box;
}

.div-input-create {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.input-create-text{
    margin: 0;
    font-size: 12px;
}
.custom-placeholder {
    color: rgba(16, 14, 113, 1);
    font-size: 16px;
    padding-left: 10px;
}
.custom-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    height: 44px;
    padding-left: 24px;
    border-radius: 8px;
    background: white;
    border: 1px solid #ccc;
    font-size: 16px;
    color: rgba(16, 14, 113, 1);
    cursor: pointer;
    overflow: hidden;
}

.custom-select:focus {
    border-color: #000;
    outline: none;
}

.custom-select-wrapper {
    position: relative;
    overflow: hidden;
}

.custom-select-wrapper::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    width: 0;
    height: 0;
    pointer-events: none;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #000;
    transform: translateY(-50%);
}

.custom-select option {
    padding-top: 5px;
    padding-bottom: 5px;
}

.input-error {
    border-color: red;
}
.modal-error{
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: red;

}

.error-text {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}
.input-container {
    position: relative;
    margin-bottom: 0px;
    width: 100%;
}
.input-modal {
    width: 100%;
    border-radius: 8px;
    padding: 24px 24px 4px;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: rgba(16, 14, 113, 1);
    position: relative;
}

.modal-button {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    gap: 12px;
}
.floating-label {
    position: absolute;
    top: 50%;
    left: 24px;
    transform: translateY(-50%);
    transition: all 0.2s ease-in-out;
    color: rgba(102, 108, 114, 1);
    pointer-events: none;
    font-size: 16px;
    line-height: 24px;
}

.input-modal:active + .floating-label:active,
.filled {
    padding: 0 0 35px;
    font-size: 16px;
    color: rgba(16, 14, 113, 1) ;
}

.modal-button {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    gap: 12px;
}
.done-modal {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.userPlus{
    font-family: 'Roboto', sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 45px;
    text-align: center;
    margin: 0;
    margin-bottom: 72px;
}
.button-done {
    padding: 16px;
    width: 100%;
    border: none;
    border-radius: 12px;
    font-family: 'Roboto', sans-serif;
    color: rgba(16, 14, 113, 1);
    background-color: white;
    margin-top: 64px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
}
.button-done:hover {
    padding: 16px;
    width: 100%;
    border: none;
    border-radius: 12px;
    font-family: 'Roboto', sans-serif;
    color: white;
    background-color: rgba(16, 14, 113, 1);
    margin-top: 64px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
}
.input-error {
    background-color: red;
}

.error-message {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: red;
    margin: 0;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
}
.button-table-take {
    align-self: center;
    align-items: center;
    text-align: center;
    padding: 7px 32px;
    width: 100%;
    height: auto;
    color:  black;
    text-align: center;
    font-family: 'Roboto', bold;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    border-radius: 8px;
    background: red;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.button-table:hover {
    background-color: rgba(16, 14, 113, 1);
    color: white;
}

@media (max-width: 768px) {
    .table-order {
        margin-left: 0;
        width: 95vw;
        padding: 0;
        border-radius: 2.5vw;
        border: none;
        background: rgba(243, 244, 248, 1);
    }

    .table {
        display: none;
    }

    .mobile-view {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .mobile-row {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: white;
        padding: 16px 12px;
        border-radius: 8px;
        gap: 1.25vw;
        border: none;
    }

    .mobile-cell {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1.25vw;

    }

    .row-text {
        font-family: 'Roboto', sans-serif;
        font-size: 3.75vw;
        font-weight: 400;
        line-height: 7.5vw;
        text-align: left;
    }
    .row-text-1{
        font-family: 'Roboto', sans-serif;
        font-size: 3.75vw;
        font-weight: 600;
        line-height: 7.5vw;
        text-align: left;
    }
    .button-div-table {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .button-table {
        padding: 3px 42.5px;
        width: auto;
        font-weight: 400;
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        height: auto;
        margin: 0;
    }

    .button-table:hover {
        background-color: rgba(16, 14, 113, 1);
        color: white;
    }


    .button-div {
        width: 100%;
        margin-top: 1.88vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 3.13vw;
    }

    .div-pages {
        gap: 0px;
        margin-top: 0px;
        padding-bottom: 0px;
    }
    .status-color-name{
        display: inline-block;
        padding: 2px 4px;
        border-radius: 8px;
        text-align: center;
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
    }
    .modal-div-barcode {
        display: flex;
        flex-direction: column;
        gap:20px;
        align-items: center;
    }
    .image-style-barcode{
        width: 100%;
        padding: 5px 10px;
        border-radius: 20px;
        background-color: white;
    }
    .button-modal-barcode{
        width: 100px;
        padding: 5px 8px;
        border-radius: 12px;
        border: 2px solid white;
        background-color: rgba(16, 14, 113, 1);
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: white;
    }
    .modal-div{
        margin-top: 0px;
    }
    .modal-title {
        margin: 0;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        color: white;
    }
    .input-div {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
    .text-input {
        margin: 0;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
    }
    .input-modal {
        border-radius: 8px;
        padding: 16px 24px 8px;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: rgba(102, 108, 114, 1);
    }
    .input-modal:active + .floating-label:active,
    .filled {
        padding: 0 0 30px;
        font-size: 16px;
        color: rgba(16, 14, 113, 1) ;
    }
    .custom-select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 100%;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        height: 38px;
        padding: 8px 12px;
        padding-left: 12px;
        border-radius: 8px;
        background: white;
        border: 1px solid #ccc;
        font-size: 16px;
        color: rgba(16, 14, 113, 1);
        cursor: pointer;
        overflow: hidden;
    }

    .custom-select:focus {
        border-color: #000;
        outline: none;
    }

    .custom-select-wrapper {
        position: relative;
        overflow: hidden;
    }

    .custom-select-wrapper::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 10px;
        width: 0;
        height: 0;
        pointer-events: none;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #000;
        transform: translateY(-50%);
    }

    .custom-select option {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .input-error {
        border-color: red;
    }
    .modal-button {
        display: flex;
        flex-direction: row;
        margin-top: 12px;
        gap: 12px;
    }
    .button-modal {
        width: 90%;
        padding: 8px 0;
        border-radius: 12px;
        border: 2px solid white;
        background-color: white;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: rgba(16, 14, 113, 1);
    }
    .done-modal {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .userPlus{
        font-family: 'Roboto', sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 45px;
        text-align: center;
        margin: 0;
        margin-bottom: 20px;
    }
    .button-done {
        padding: 5px;
        width: 100%;
        border: none;
        border-radius: 12px;
        font-family: 'Roboto', sans-serif;
        color: rgba(16, 14, 113, 1);
        background-color: white;
        margin-top: 22px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
    }
    .button-done:hover {
        padding: 16px;
        width: 100%;
        border: none;
        border-radius: 12px;
        font-family: 'Roboto', sans-serif;
        color: white;
        background-color: rgba(16, 14, 113, 1);
        margin-top: 64px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
    }
    .toggle-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: 'JB Sans', sans-serif;
        line-height: 15.36px;
        font-size: 12px;
        font-weight: 600;
        color: white;
        border-radius: 8px;
        color: var(--Light-Text-Text, rgba(32, 31, 31, 1));
    ;
        border: none;
        background-color: rgba(234, 236, 240, 1);
    }
}




