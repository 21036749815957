
.container {
    width: 100%;
}

.type {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.style-cube-svg {
    width: 24px;
    height: 24px;
}

.type-text {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 11px;
    font-weight: 500;
    line-height: 11.72px;
    text-align: left;
    color: rgba(153, 153, 153, 1);
}

.type-div {
    display: flex;
    flex-direction: column;
    gap: 7px;
    align-items: center;
}

.type-div.active .style-cube-svg,
.type-div.active .type-text {
    color: rgba(27, 99, 207, 1);
    fill: rgba(27, 99, 207, 1); /* изменение цвета SVG */
}

.active-svg {
    fill: rgba(27, 99, 207, 1);
}

.active-text {
    color: rgba(27, 99, 207, 1);
}
