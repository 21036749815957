.container {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 348px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: -2px 0 5px rgba(0,0,0,0.1);
    z-index: 30;
    border: 1px solid  #100E71;
    background: #FFF;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
}

.container::-webkit-scrollbar {
    display: none;
}

.container {
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.content {
    margin: 24px 45px 24px 12px;
    height: auto;
    flex-grow: 1; /* Позволяет контенту занимать оставшееся пространство */
    overflow-x: auto; /* Включаем только вертикальную полосу прокрутки */
}

.content::-webkit-scrollbar {
    display: none;
}

.content {
    scrollbar-width: none;
}

.content {
    -ms-overflow-style: none;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.header-text {
    margin: 0;
    color:  #100E71;
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 55px; /* 229.167% */
}

.header-button {
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.status {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    gap: 21px;
}

.status-header {
    margin-top: 36px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.status-header-text {
    margin: 0;
}

.status-header-button {
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: -2px;
    color:  #100E71;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 128%; /* 15.36px */
    border-radius: 8px;
    background: #CAE0FF;
    cursor: pointer;
}

.status-header-text {
    color:  #201F1F;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 128%; /* 20.48px */
}

.order-create {
    margin-top: 8px;
    display: flex;
    gap: 12px;
    flex-direction: row;
    flex-wrap: wrap;
}

.button-filter {
    border-radius: 8px;
    padding: 5.5px 16px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 128%;
}

.activeStatus {
    opacity: 1;
}

.selector {
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-top: 36px;
}

.selector-text {
    margin: 0;
    color: #201F1F;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 128%; /* 20.48px */
}

.selector-number {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.selector-number-10 {
    padding: 5.5px 10px;
    color: #100E71;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 128%;
    border-radius: 8px;
    border: 1px solid #100E71;
    background: #FFF;
}

.activeSelector {
    color:  white;
    border: 1px solid white;
    background: #100E71;
}

.button-filter-city {
    color:  #201F1F;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    padding: 8px 12px;
    border-radius: 8px;
    background: #F3F4F8;
    border: none;
    cursor: pointer;
}

.activeCity {
    color:  white;
    background: #100E71;
}

.date {
    margin-top: 36px;
    display: flex;
    flex-direction: column;
}

.react-datepicker {
    margin-top: 18px;
    padding: 2px 4px;
    border-radius: 8px;
    border: 2px solid #100E71;
    color: #201F1F;
    font-family: "JB Sans", sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 117.647% */
}
.react-datepicker::placeholder {
    color: #201F1F;
    font-weight: 500;
    font-size: 16px;
    font-family: "JB Sans", sans-serif;
}

.line {
    margin-top: 0px;
    width: 100%;
}

.footer {
    width: 100%;
    padding: 24px;
    background: #F3F4F8;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    position: sticky;
    bottom: 0;
}
.footer-text {
    margin: 0;
    color: #666C72;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 90% */
}
.footer-button {
    border: none;
    border-radius: 12px;
    background:  #100E71;
    color: #FFF;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    width: 100%;
    padding: 12px 24px;
}

@media(max-width: 768px) {
    .container {
        height: 100%;
        width: 100%;
        overflow-x: hidden; /* Убираем горизонтальный скролл */
        overflow-y: scroll; /* Добавляем вертикальный скролл */
    }

    .container::-webkit-scrollbar {
        display: none; /* Скрываем ползунок для WebKit */
    }

    .container {
        scrollbar-width: none; /* Скрываем ползунок для Firefox */
        -ms-overflow-style: none; /* Скрываем ползунок для Internet Explorer и Edge */
    }

    .content {
        margin: 0px 24px 0px 50px;
        height: 80%;
        flex-grow: 1;
        overflow-x: hidden; /* Убираем горизонтальный скролл */
        overflow-y: scroll; /* Добавляем вертикальный скролл */
    }

    .content::-webkit-scrollbar {
        display: none; /* Скрываем ползунок для WebKit */
    }

    .content {
        scrollbar-width: none; /* Скрываем ползунок для Firefox */
        -ms-overflow-style: none; /* Скрываем ползунок для Internet Explorer и Edge */
    }

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .header-text {
        margin: 0;
        color:  #100E71;
        font-family: 'Roboto', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 55px; /* 229.167% */
    }

    .header-button {
        border: none;
        background-color: transparent;
        cursor: pointer;
    }

    .status {
        margin-top: 0;
        display: flex;
        flex-direction: column;
        gap: 21px;
    }

    .status-header {
        margin-top: 36px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .status-header-text {
        margin: 0;
    }

    .status-header-button {
        border: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: -2px;
        color:  #100E71;
        text-align: center;
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 128%; /* 15.36px */
        border-radius: 8px;
        background: #CAE0FF;
        cursor: pointer;
    }

    .status-header-text {
        color:  #201F1F;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 128%; /* 20.48px */
    }

    .order-create {
        margin-top: 8px;
        display: flex;
        gap: 12px;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .button-filter {
        border-radius: 8px;
        padding: 5.5px 16px;
        text-align: center;
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 128%; /* 15.36px */
    }

    .selector {
        display: flex;
        flex-direction: column;
        gap: 18px;
        margin-top: 36px;
    }

    .selector-text {
        margin: 0;
        color: #201F1F;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 128%; /* 20.48px */
    }

    .selector-number {
        display: flex;
        flex-direction: row;
        gap: 8px;
    }

    .selector-number-10 {
        padding: 5.5px 10px;
        color: #100E71;
        text-align: center;
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 128%;
        border-radius: 8px;
        border: 1px solid #100E71;
        background: #FFF;
    }
    .activeSelector {
        color:  white;
        border: 1px solid white;
        background: #100E71;
    }

    .button-filter-city {
        color:  #201F1F;
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%;
        padding: 8px 12px;
        border-radius: 8px;
        background: #F3F4F8;
        border: none;
        cursor: pointer;
    }
    .activeCity {
        color:  white;
        background: #100E71;
    }

    .date {
        margin-top: 36px;
        display: flex;
        flex-direction: column;
        margin-bottom: 0px;
        padding-bottom: 200px;
    }

    .react-datepicker {
        margin-top: 18px;
        border: 1.5px solid #100E71;
        color: #201F1F;
        font-family: "JB Sans", sans-serif;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 117.647% */
    }

    .line {
        margin-top: 0px;
        width: 100%;

    }

    .footer {
        flex: 0 0 auto;
        width: 100%;
        padding: 24px;
        background: #F3F4F8;
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        position: sticky;

    }
    .footer-text {
        margin: 0;
        color: #666C72;
        text-align: center;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 90% */
    }
    .footer-button {
        border: none;
        border-radius: 12px;
        background:  #100E71;
        color: #FFF;
        text-align: center;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        width: 100%;
        padding: 12px 24px;

    }
}


