.select-container {
    display: flex;
    align-items: center;
    border: none;
    border-radius: 8px;
    background-color: white;
    padding: 5px 100px 5px 5px;
    cursor: pointer;
    position: relative;
}

.select-container:hover,
.select-container.hover {
    background-color: rgba(16, 14, 113, 1);
}

.select-container:hover .label-style,
.select-container:hover .select-wrapper,
.select-container:hover .static-text,
.select-container:hover .select-style {
    color: white;
}

.label-style {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
}

.select-wrapper {
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.static-text {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #3B3C5A;
}

.select-style {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    outline: none;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
}

.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    border-radius: 8px;
    background-color: white;
    border: none;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 20;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 18px;
}

.option-style {
    padding: 9px 19px;
    color: rgba(16, 14, 113, 1);
    border: 1px solid rgba(16, 14, 113, 1);
    cursor: pointer;
    font-weight: 600;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 17.92px;
}

.option-style:hover {
    color: white;
    background-color: rgba(16, 14, 113, 1);
}

.selected {
    color: white;
    background-color: rgba(16, 14, 113, 1);
}
@media(max-width: 768px) {
    .select-container {
        display: flex;
        align-items: center;
        border: none;
        border-radius: 8px;
        background-color: white;
        padding: 5px 5px 5px 5px;
        cursor: pointer;
        position: relative;
    }

    .select-container:hover,
    .select-container.hover {
        background-color: rgba(16, 14, 113, 1);
    }

    .select-container:hover .label-style,
    .select-container:hover .select-wrapper,
    .select-container:hover .static-text,
    .select-container:hover .select-style {
        color: white;
    }

    .label-style {
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
    }

    .select-wrapper {
        display: flex;
        flex-direction: column;
        cursor: pointer;
    }

    .static-text {
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #3B3C5A;
    }

    .select-style {
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        outline: none;
        background-color: transparent;
        padding: 0;
        cursor: pointer;
    }

    .dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        border-radius: 8px;
        background-color: white;
        border: none;
        width: 100%;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        z-index: 20;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 12px 18px;
    }

    .option-style {
        padding: 5px 10px;
        color: rgba(16, 14, 113, 1);
        border: 1px solid rgba(16, 14, 113, 1);
        cursor: pointer;
        font-weight: 600;
        border-radius: 8px;
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        line-height: 17.92px;
    }

    .option-style:hover {
        color: white;
        background-color: rgba(16, 14, 113, 1);
    }

    .selected {
        color: white;
        background-color: rgba(16, 14, 113, 1);
    }
}
