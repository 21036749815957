body {
    overflow-y: scroll; /* Разрешаем скроллинг */
    scrollbar-width: none; /* Убираем скролл для Firefox */
    -ms-overflow-style: none; /* Для Internet Explorer */
}

body::-webkit-scrollbar {
    display: none; /* Убираем скролл для Chrome, Safari и Opera */
}
.container {
    width: 100vw;
    margin: 0 auto;
}
.content {
    margin: 44px 0 200px 53px;
}


.title{

    color: #100E71;
    font-family: 'Roboto', sans-serif;
    font-size: 44px;
    font-style: normal;
    font-weight: 600;
    line-height: 55px;
}
.type{
    margin-top: 60px;
    background-color: red;
    padding: 12px 24px;
    border-radius: 8px;
    background: #FFF;
    width: 412px;
}
.choose-tarif {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.choose-tarif-text{
    border-radius: 6px;
    border: 2px solid #5FAD05;
    background: rgba(95, 173, 5, 0.60);
    padding: 2px 17px;
    margin: 0;
    color: #000;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}
.type-div{

}
.type-title{
    color: #5FAD05;
    font-family: 'Roboto', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    margin: 0;
}
.type-text{
    margin: 0;
    margin-top: 8px;
    color: #100E71;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}
.advantages{
    margin-top: 24px;
}
.advantages-text{
    margin: 0;
    color: #0B1F35;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}
.price-span{
    color:  #0B1F35;
    font-family: "SF Pro Display", sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 300;
    line-height: 26px;
}
.price{
    margin-top: 40px ;
}
.price-text{
    margin: 0;
    color: #100E71;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "SF Pro Text", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}
.button-div-style {
    width: 100%;
    margin-top: 40px;
    gap: 12px;
    display: flex;
    flex-direction: row;
}
.button-style {
    width: 200px;
    height: 56px;
    background-color: white;
    border-radius: 12px;
    border: 2px solid #100E71;
    color: #100E71;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    cursor: pointer;
}
.button-style:hover {
    background-color: #100E71;
    color: white;
}
.calculator-card {
    display: none;
}
@media (max-width: 768px) {
    .container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; /* Высота на весь экран */
        overflow: hidden; /* Скрываем прокрутку внутри контейнера */
        margin: 0 auto;
    }
    .content {
        height: 100%; /* Высота для всего экрана */
        overflow-y: scroll; /* Включаем вертикальную прокрутку */
        padding-right: 17px; /* Обеспечиваем пространство для контента */
    }

    /* Скрываем полосу прокрутки для всех браузеров */
    .content::-webkit-scrollbar {
        width: 0; /* Убираем ширину скроллбара */
    }
    .content {
        scrollbar-width: none; /* Убираем скроллбар для Firefox */
        -ms-overflow-style: none; /* Убираем скроллбар для IE и Edge */
    }
    .content {
        height: 100vh;
        margin: 82px 12px 20px 12px;
        padding-bottom: 200px;
    }
    .title {
        font-size: 24px;
        font-weight: 600;
        line-height: 55px;
    }
    .type {
        margin-top: 12px;
        padding: 16px 12px;
        border-radius: 8px;
        background: #FFF;
        width: 100%;
    }
    .choose-tarif {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    .choose-tarif-text {
        border-radius: 1.88vw;
        border: none;
        background: rgba(226, 251, 197, 1);
        color: rgba(54, 89, 15, 1);
        padding: 4px 16px;
        margin: 0;
        color: #000;
        text-align: center;
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 5.63vw;
    }
    .type-div {
    }
    .type-title {
        color: rgba(95, 173, 5, 1);
        font-family: 'Roboto', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        margin: 0;
    }
    .type-text {
        margin-top: 4px;
        font-weight: 400;
        line-height: 18px;
        font-size: 13px;
    }
    .advantages {
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-top: 4px;
    }
    .advantages-text {
        margin: 0;
        margin-top: 0;
        font-size: 12px;
        font-weight: 400;
    }
    .price-span {
        color: rgba(11, 31, 53, 1);
        font-family: 'Roboto', sans-serif;
        font-size: 36px;
        font-weight: 300;
        line-height: 26px;
    }
    .price {
        margin-top: 12px;
    }
    .price-text {
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;

    }
    .button-div-style {
        width: 100%;
        margin-top: 3.75vw;
        gap: 3.75vw;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .button-style {
        width: 100%;
        height: 13.75vw;
        background-color: white;
        border-radius: 3.75vw;
        border: 0.63vw solid #100E71;
        color: #100E71;
        text-align: center;
        font-family: 'Roboto', sans-serif;
        font-size: 4.38vw;
        font-style: normal;
        font-weight: 500;
        line-height: 7.5vw;
        cursor: pointer;
    }
    .button-style:hover {
        background-color: #100E71;
        color: white;
    }
    .line-status {
        margin-top: 3.75vw;
        width: 100%;
        height: 0.31vw;
        background-color: rgba(215, 218, 223, 1);
    }
    .calculator-card {
        margin-top: 12px;
        width: 100%;
        display: inline-block;
        background-color: #fff;
        border-radius: 24px;
        padding: 1.5rem;
    }

    .calculator-slider {
        appearance: none;
        width: 100%;
        height: 14px;
        border: 2px solid #f0f0f9;
        border-radius: 6px;
        margin-top: 1rem;
    }

    .calculator-slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 28px;
        height: 28px;
        background: #f0f0f9;
        cursor: pointer;
        border-radius: 50%;
        border: 2px solid #100e71;
    }

    .calculator-slider::-moz-range-thumb {
        width: 28px;
        height: 28px;
        background: #f0f0f9;
        border: 2px solid #100e71;
        cursor: pointer;
        border-radius: 50%;
    }

    .calculator-slider::-webkit-slider-thumb::before {
        background-color: #100e71;
    }
    .calculator-slider::-moz-range-progress {
        background-color: #100e71;
    }
    .calculator-slider::-moz-range-track {
        background-color: blue;
    }
    .calculator-slider::-webkit-slider-runnable-track::before {
        background-color: #100e71;
    }
    .primary-text-sm{
        color: rgba(16, 14, 113, 1);
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: -0.4000000059604645px;
        text-align: left;

    }
    .tab-text{
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: -0.15000000596046448px;
        text-align: start;
        color: rgba(16, 14, 113, 1);
    }
    .calc-form-text {
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        color: rgba(124, 125, 147, 1);
    }
    .weight-wrap{
        padding: 17.5px 18px;
        border: 1px solid rgba(234, 234, 237, 1);
        border-radius: 12px;
        margin-top: 4px;
        margin-bottom: 12px;
    }
    .weight-text{
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: rgba(16, 14, 113, 1);
    }
    .row-wrap2 {
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: center;
    }
    .box-price{
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: rgba(16, 14, 113, 1);
    }
    .weight-wrap-1{
        padding: 17.5px 18px;
        border: 1px solid rgba(234, 234, 237, 1);
        border-radius: 12px;
        margin-top: 4px;
    }
    .calc-form-text-1 {
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        color: rgba(124, 125, 147, 1);
    }
    .line {
        width: 100%;
        border-top: 1px solid #eaeaed;
        margin: 20px 0;
    }
    .grid-wrap {
        display: grid;
        grid-template-columns: 48% 48%;
        gap: 2rem;
    }
    .with-right-line {
        border-right: 1px solid #eaeaed;
    }
    .primary-text-lg{
        font-family: 'Roboto', sans-serif;
        color: rgba(16, 14, 113, 1);
        font-size: 25.7px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: -0.52px;
        text-align: left;

    }

}