@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

body,
html,
#root {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;

    font-family: 'Roboto', sans-serif;
    background-color: rgba(243, 244, 248, 1);
    user-select: none;
    -webkit-overflow-scrolling: touch;
    /*overflow-x: hidden;*/
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}
h1{
    margin: 0;
    font-family: 'Roboto', sans-serif;
}
h2{
    margin: 0;
    font-family: 'Roboto', sans-serif;
}
p{
    margin: 0;
    font-family: 'Roboto', sans-serif;
}
