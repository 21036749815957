@font-face {
    font-family: 'Furore';
    src: url('/src/fonts/Furore.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.container {
    width: 100vw;
    padding: 0;
    padding-bottom: 2rem;
}
.header {
    background-color: #100e71;
    padding: 1rem 6rem;
    display: flex;
    justify-content: space-between;
    color: #fff;
    align-items: center;
}
.row-wrap {
    display: flex;
    gap: 1rem;
    align-items: center;
}
.qr-div{
    padding: 64px;
    border-radius: 48px;
    /*background: #FCFBFA;*/
    background: radial-gradient(75% 75% at 50% 50%, rgba(16, 14, 113, 0.40) 0%, rgba(16, 14, 113, 0.00) 100%);
}
.qr-style{
    background-color: white;
    width: 377.688px;
    height: 377.688px;
    flex-shrink: 0;
    padding: 46px;
    border-radius: 40px;
}
.row-wrap2 {
    display: flex;
    gap: 6px;
    align-items: center;
}
.row-wrap3 {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}
.row-wrap-header {
    display: flex;
    gap: 6px;
    align-items: center;
}
.row-wrap4 {
    display: flex;
    gap: 2rem;
    align-items: center;
}
.column-wrap {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.column-wrap2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.column-wrap3 {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}
.content {
    padding: 1rem 6rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    background-image: url('./../../assets/Ellipse.svg');
    background-position-x: left;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #ffffff;
}

.card1 {
    display: grid;
    grid-template-columns: 48% 48%;
    gap: 2rem;
    margin-top: 6rem;
    align-items: center;
}

.grid-wrap {
    display: grid;
    grid-template-columns: 48% 48%;
    gap: 2rem;
}

.grid-wrap2 {
    display: grid;
    grid-template-columns: 48% 48%;
    gap: 2rem;
    justify-content: center;
    justify-items: center;
    align-self: center;
}

.calculator-card {
    background-color: #fff;
    border-radius: 24px;
    padding: 1.5rem;
}

.calculator-slider {
    appearance: none;
    width: 100%;
    height: 14px;
    border: 2px solid #f0f0f9;
    border-radius: 6px;
    margin-top: 1rem;
}

.calculator-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 28px;
    height: 28px;
    background: #f0f0f9;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid #100e71;
}

.calculator-slider::-moz-range-thumb {
    width: 28px;
    height: 28px;
    background: #f0f0f9;
    border: 2px solid #100e71;
    cursor: pointer;
    border-radius: 50%;
}

.calculator-slider::-webkit-slider-thumb::before {
    background-color: #100e71;
}
.calculator-slider::-moz-range-progress {
    background-color: #100e71;
}
.calculator-slider::-moz-range-track {
    background-color: blue;
}
.calculator-slider::-webkit-slider-runnable-track::before {
    background-color: #100e71;
}

.header-text {
    color: #100e71;
    font-size: 60px;
    line-height: 100%;
    font-weight: 700;
    margin: 0;
}

.header-text2 {
    color: #100e71;
    font-size: 38px;
    text-align: center;
    line-height: 100%;
    margin: 0;
}
.header-text3 {
    color: #100e71;
    font-size: 72px;
    text-align: center;
    line-height: 100%;
    margin: 0;
}

.hint-wrap {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}

.hint-text {
    color: #797b80;
    font-size: 24px;
    margin: 0;
}

.primary-button {
    border: 1px none;
    border-radius: 12px;
    width: 170px;
    background-color: #100e71;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    padding: 1rem 2rem;
}

.secondary-button {
    border: 1px solid #100e71;
    border-radius: 12px;
    background-color: transparent;
    color: #100e71;
    font-size: 16px;
    padding: 1rem 2rem;
    cursor: pointer;
    height: min-content;
}
.secondary-button2 {
    border-radius: 12px;
    background-color: #ffffff9e;
    color: #100e71;
    font-size: 14px;
    font-weight: 500;
    padding: 1rem;
    cursor: pointer;
    height: min-content;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    text-align: center;
}
.secondary-button3 {
    border-radius: 12px;
    border: none;
    background-color: #ffffff;
    color: #100e71;
    font-size: 16px;
    font-weight: 400;
    padding: 1rem;
    cursor: pointer;
}
.primary-text-xl {
    color: #100e71;
    font-size: 32px;
    font-weight: 600;
    margin: 0;
}
.primary-text-lg {
    color: #100e71;
    font-size: 26px;
    font-weight: 600;
    margin: 0;
}
.primary-text-md {
    color: #100e71;
    font-size: 24px;
    font-weight: 600;
    line-height: 38px;
}
.primary-text-sm {
    color: #100e71;
    font-size: 20px;
    font-weight: 600;
}
.color-text {
    color: #100e71cc;
    font-size: 20px;
    font-weight: 500;
    line-height: 38px;
}

.tab-text {
    background-color: #100e71;
    color: #fff;
    width: 100%;
    border-radius: 8px;
    padding: 0.25rem 1rem;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
}

.calc-form-text {
    color: #7c7d93;
    font-size: 12px;
    line-height: 2rem;
    margin: 0;
}

.weight-wrap {
    border: 1px solid #eaeaed;
    border-radius: 12px;
    padding: 1rem;
    margin-bottom: 12px;
}

.weight-text {
    color: #100e71;
    font-size: 14px;
    font-weight: 400;
}

.line {
    width: 100%;
    border-top: 1px solid #eaeaed;
    margin: 20px 0;
}

.with-right-line {
    border-right: 1px solid #eaeaed;
}

.banner {
    background-size: cover;
    background-image: url('/src/assets/Frame.png');
    background-repeat: no-repeat;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 2rem;
}

.banner-text {
    color: #fff;
    font-size: 36px;
    margin: 0;
}

.banner-tab {
    color: #fff;
    border: 2px solid #fff;
    border-radius: 26px;
    padding: 0.5rem 4rem;
}

.input-style {
    align-items: center;
    border: 1px none;
    width: 100%;
    height: 40px;
    padding: 8px 20px;
    border-radius: 24px 0 0 24px;
    font-family: Inter, sans-serif;
    font-size: 16px;
    color: rgba(16, 14, 113, 1);
    background-image: url('../../assets/lupa.png');
    background-repeat: no-repeat;
    background-position: 20px 12px;
    padding-left: 52px;
    background-size: 16px 16px;
}
::placeholder {
    color: rgba(16, 14, 113, 1);
    align-items: center;
    display: flex;
}
.input {
    gap: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 24px;
    width: 50%;
}
.search-button {
    border: 1px solid #fff;
    border-radius: 24px;
    background-color: #100e71;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    padding: 0 2rem;
}

.colorfull-card-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}

.colorfull-card-with-scroll {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}
.carousel-card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
}
.colorfull-card {
    background-color: #f2f2fc;
    border-radius: 24px;
    padding: 1rem;
}
.colorfull-card1 {
    background: linear-gradient(
        216.01deg,
        rgba(191, 131, 251, 0.2) 46.73%,
        rgba(16, 14, 113, 0.2) 75.83%,
        rgba(16, 14, 113, 0.3) 121.55%
    );
    border-radius: 48px;
    padding: 2rem 1rem 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.colorfull-card-target {
    background-color: #f2f2fc;
    border-radius: 24px;
    padding: 1rem;
    position: relative;
    overflow: hidden;
}
.colorfull-card-img {
    position: absolute;
    bottom: -15%;
    left: -15%;
}
.colorfull-card-img-clock {
    position: absolute;
    bottom: -15%;
    left: -15%;
    transform: rotate(25deg);
}
.feedback-item {
    background-color: #fff;
    border-radius: 20px;
    padding: 2rem 1rem 1rem 2rem;
}
.feedback-wrap {
    background-color: #f2f2fc;
    border-radius: 24px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.feedback-name {
    font-size: 24px;
    font-weight: 500;
    color: #181a4b;
}
.feedback-city {
    color: #646581;
    font-size: 14px;
    font-weight: 400;
    line-height: 0;
    margin: 0;
}
.feedback-text {
    color: #100e71;
    font-size: 18px;
    font-weight: 700;
    line-height: 38px;
}

.indicator-text {
    font-size: 96px;
    float: right;
    color: #100e71;
    font-family: 'Furore';
    text-align: end;
}

.qr-text-number {
    color: #100e71;
    font-size: 60px;
    margin: 0;
    font-family: 'Furore';
}

.qr-text-step {
    color: #201f1f;
    font-size: 40px;
    font-weight: 700;
    margin: 0;
}
.qr-text-hint {
    color: #797b80;
    font-size: 24px;
    margin: 0;
}

.footer-card {
    background: #100e711a url('/src/assets/landing-footer.svg') no-repeat right / contain;
    border-radius: 48px;
    padding: 2rem 6rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: start;
    background-size: 25%;
}

.circle {
    background-color: #b1b3b6;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin-top: 2px;
}

.active-lng {
    color: #ffffff;
    font-weight: 500;
    cursor: pointer;
}

.passive-lng {
    color: #b1b3b6;
    font-weight: 500;
    cursor: pointer;
}
.hint-text2 {
    color: #100e71b2;
    font-size: 18px;
    width: 95%;
    padding-bottom: 1rem;
    line-height: 23.4px;
    font-weight: 500;
    margin: 0.25rem 0 0;
}

.feedback-content {
    width: calc(100vw - 240px);
}

.modal-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 1rem;
}
.modal-content {
    background-color: #fff;
    border-radius: 12px;
    color: #1a1c1f;
    text-align: left;
    padding: 1rem;
}
.modal-header {
    font-size: 26px;
    font-weight: 400;
    color: #ffffff;
}
.modal-text {
    font-size: 16px;
    font-weight: 500;
}
.modal-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.5rem;
}
.modal-date-wrap {
    display: flex;
    flex-direction: column;
}
.date {
    color: #1a1c1f;
    font-size: 14px;
    font-weight: 500;
}
.time {
    color: #666c72;
    font-size: 14px;
    font-weight: 400;
}

.status {
    display: inline-block;
    border-radius: 8px;
    border: 1px solid #797b80;
    background: #eaecf0;
    padding: 5.5px 16px;
    color: #797b80;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 128%;
    margin-top: 24px;
}
.box-price {
    color: #100e71;
    font-size: 20px;
    font-weight: 400;
}

@media (max-width: 1600px) {
    .colorfull-card-img,
    .colorfull-card-img-clock {
        left: -30%;
        width: 100%;
        height: 65%;
    }
}

@media (max-width: 800px) {
    .header {
        padding: 1rem;
    }
    .row-wrap-header {
        display: none;
    }
    .content {
        padding: 1rem;
        gap: 2rem;
    }
    .card1 {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
    }
    .first-info {
        width: 85%;
    }
    .row-wrap,
    .grid-wrap2,
    .colorfull-card-grid,
    .carousel-card-grid {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        width: -webkit-fill-available;
        width: -moz-available;
    }
    .header-text {
        font-size: 24px;
        text-align: center;
        line-height: 40px;
    }
    .header-text2 {
        font-size: 30px;
        text-align: center;
        line-height: 50px;
    }
    .header-text3 {
        font-size: 30px;
        text-align: center;
        line-height: 50px;
    }
    .hint-text {
        font-size: 18px;
        width: 95%;
        text-align: center;
        padding-bottom: 1rem;
    }
    .hint-text2 {
        font-size: 18px;
        padding-top: 1rem;
        line-height: 23.4px;
    }
    .feedback-content {
        width: 110%;
    }
    .feedback-item {
        width: calc(100vw - 80px);
    }
    .footer-card {
        background: #100e711a;
    }
    .calculator-card {
        width: -webkit-fill-available;
        width: -moz-available;
    }

    .primary-button {
        width: -webkit-fill-available;
        width: -moz-available;
    }
    .secondary-button {
        width: -webkit-fill-available;
        width: -moz-available;
    }
    .secondary-button2 {
        width: -webkit-fill-available;
        width: -moz-available;
    }
    .input {
        width: 100%;
        height: 50px;
        gap: 0;
    }
    .input-style {
        height: 50px;
        background-position: 4px;
        padding: 0 0 20px 26px;
    }
    input::placeholder {
        word-break: break-all;
        white-space: pre-wrap;
        align-items: center;
        font-size: 10px;
    }
    .primary-text-xl {
        font-size: 18px;
    }
    .primary-text-lg {
        font-size: 18px;
    }
    .primary-text-md {
        font-size: 16px;
        line-height: 22px;
    }
    .primary-text-sm {
        font-size: 14px;
        padding-bottom: 1rem;
    }
    .color-text {
        font-size: 14px;
    }
    .banner {
        background-image: url('/src/assets/bg.png');
    }
    .banner-text {
        font-size: 24px;
        font-weight: 600;
        width: 80%;
    }
    .feedback-text {
        font-size: 12px;
    }
    .colorfull-card-img,
    .colorfull-card-img-clock {
        display: none;
    }
    .indicator-text {
        font-size: 60px;
        float: right;
        color: #100e71;
        font-family: 'Furore';
        text-align: end;
    }
    .qr-style{
        background-color: white;
        width: 100%;
        height: 100%;
        flex-shrink: 0;
        padding: 46px;
        border-radius: 40px;
    }
    .qr-div{
        padding: 25px;
        border-radius: 48px;
        /*background: #FCFBFA;*/
        background: radial-gradient(75% 75% at 50% 50%, rgba(16, 14, 113, 0.40) 0%, rgba(16, 14, 113, 0.00) 100%);
    }
}
