@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
.table-container {
    border: 1px solid #F4F4F4;
    border-radius: 8px;
}

.table-order {
    margin-top: 24px;
    width: 100%;
    padding: 0;
    background: #FFF;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.select-status {
    font-family: 'Roboto', sans-serif;
    width: 100%;
    font-size: 12px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
}

.option-status {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
}

.modal-div {
    width: 100%;
}

.div-pages {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 24px;
}

.button-pages {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    color: rgba(102, 108, 114, 1);
    background-color: white;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid rgba(223, 227, 232, 1);
}

.button-pages:hover {
    border: 1px solid rgba(16, 14, 113, 1);
    color: rgba(16, 14, 113, 1);
}

.button-pages:focus {
    border: 1px solid rgba(16, 14, 113, 1);
    color: rgba(16, 14, 113, 1);
}

.modal-title {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 45px;
    color: white;
}

.input-div {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.text-input {
    margin: 0;
    margin-bottom: -4px;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
}

.input-modal {
    border-radius: 8px;
    padding: 16px 24px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: rgba(102, 108, 114, 1);
}

.modal-button {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    gap: 12px;
}

.button-modal {
    width: 100%;
    padding: 16px 0;
    border-radius: 12px;
    border: 2px solid white;
    background-color: white;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: rgba(16, 14, 113, 1);
}

.button-modal:hover {
    background-color: rgba(16, 14, 113, 1);
    color: white;
}

.table {
    table-layout: fixed;
    background: rgba(255, 255, 255, 1);
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
}

.th-style:first-child {
    word-wrap: break-word;
    padding: 28px 5px;
    margin: 0;
    width: 123px !important;
    color: #666C72;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.th-style {
    word-wrap: break-word;
    padding: 25px 5px;
    width: 80px !important;
    color: #666C72;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    border: 1px solid rgba(16, 14, 113, 1);
    border-bottom: 4px solid rgba(16, 14, 113, 1);
}
.tbody:nth-child(even) {
    background-color: rgba(243, 244, 248, 1);
    border: none;
}

.td-style {
    word-wrap: break-word;
    border: 1px solid rgba(16, 14, 113, 1);
    padding: 23px 5px;
    text-align: center;
    color: #666C72;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.status-waiting {
    color: rgba(132, 70, 8, 1);
    border-radius: 8px;
    border: 1px solid #894705;
    background: #FFBD7B;
    padding: 4px 8px;
}

.status-postomat {
    color: rgba(54, 89, 15, 1);
    border-radius: 8px;
    border: 1px solid #36590F;
    background: #E2FBC5;
    padding: 4px 8px;
}

.status-lost {
    color: rgba(178, 56, 19, 1);
    border-radius: 8px;
    border: 1px solid #B23813;
    background: #FB8484;
    padding: 4px 8px;
}

.status-cargo {
    color: rgba(16, 14, 113, 1);
    border-radius: 8px;
    border: 1px solid #100E71;
    background: #CAE0FF;
    padding: 4px 8px;
}

.button-div-table {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.button-table {
    align-self: center;
    align-items: center;
    text-align: center;
    padding: 2px 0;
    width: 100%;
    height: auto;
    color:  #100E71;
    text-align: center;
    font-family: 'Roboto', bold;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    border-radius: 8px;
    background: rgba(177, 177, 170, 0.40);
}

.button-table:hover {
    background-color: rgba(16, 14, 113, 1);
    color: white;
}

.mobile-view {
    display: none;
}

@media (max-width: 768px) {
    .table-order {
        margin-left: 0;
        width: 95vw;
        padding: 0;
        border-radius: 2.5vw;
        border: 0.31vw solid #F4F4F4;
        background: rgba(243, 244, 248, 1);
    }

    .table {
        display: none;
    }

    .mobile-view {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 6vw;
    }

    .mobile-row {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: white;
        padding: 3.75vw 5vw;
        border-radius: 2.5vw;
        gap: 1.25vw;
        border: 2px solid rgba(16, 14, 113, 1);
    }

    .mobile-cell {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 1.25vw;

    }

    .row-text {
        font-family: 'Roboto', sans-serif;
        font-size: 3.75vw;
        font-weight: 400;
        line-height: 7.5vw;
        text-align: left;
    }
    .row-text-1{
        font-family: 'Roboto', sans-serif;
        font-size: 3.75vw;
        font-weight: 600;
        line-height: 7.5vw;
        text-align: left;
    }
    .button-div-table {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .button-table {
        width: 100px;
        padding: 5px 20px;
        font-family: 'Roboto', sans-serif;
        font-size: 14px
    }

    .button-table:hover {
        background-color: rgba(16, 14, 113, 1);
        color: white;
    }


    .button-div {
        width: 100%;
        margin-top: 1.88vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 3.13vw;
    }

    .div-pages {
        gap: 0px;
        margin-top: 0px;
    }
}

