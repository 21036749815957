.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.title {
    color: #100e71;
}

.input-style {
    align-items: center;
    border: 1px none;
    width: 100%;
    height: 40px;
    padding: 6px 20px;
    border-radius: 24px;
    font-family: Inter, sans-serif;
    font-size: 16px;
    color: rgba(16, 14, 113, 1);
    background-image: url('../../assets/lupa.png');
    background-repeat: no-repeat;
    background-position: 20px 14px;
    padding-left: 52px;
    background-size: 16px 16px;
}

.tabs {
    display: grid;
    grid-template-columns: 50% 50%;
    color: #100e71;
    font-size: 18px;
    font-weight: 600;
    width: 100%;
    border-radius: 24px;
    background-color: #fff;
}

.selectedTab {
    background-color: #100e71;
    color: #fff;
    border-radius: 24px;
    padding: 1rem;
    text-align: center;
    cursor: pointer;
}

.tab {
    padding: 1rem;
    text-align: center;
    cursor: pointer;
}

.table {
    /* border-radius: 8px; */
    display: grid;
    overflow: auto;
}
.table-columns-wrap {
    display: flex;
    background-color: #fff;
}

.table-data {
    border-top: 2px solid #100e71;
    max-height: 100vh;
    display: inline-block;
    overflow: auto;
}

.table-data div:nth-child(odd) {
    background-color: #f3f4f8;
}

.table-cell {
    border: 0.5px solid #100e71;
    flex: 1;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-width: 100px;
    word-break: break-all;
}

.primary-button {
    border: 1px none;
    border-radius: 12px;
    width: 170px;
    background-color: #100e71;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
    padding: 6px;
}

.filter-wrap {
    display: grid;
    grid-template-columns: 33% 33% 32%;
    grid-column-gap: 0.5rem;
}

.error {
    color: red;
}
