.container {
    width: 100%;
    margin: 0 auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.content {
    margin: 53px;
}
.userPlus{
    font-family: 'Roboto', sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 45px;
    text-align: center;
    margin: 0;
    margin-bottom: 72px;
}
.done-modal {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.button-done {
    padding: 16px;
    width: 100%;
    border: none;
    border-radius: 12px;
    font-family: 'Roboto', sans-serif;
    color: rgba(16, 14, 113, 1);
    background-color: white;
    margin-top: 64px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
}
.error-text {
    margin: 0;
    padding: 0;
    color: red;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}
.title {
    color: #100E71;
    font-family: 'Roboto', sans-serif;
    font-size: 44px;
    font-style: normal;
    font-weight: 600;
    line-height: 55px;
}
.found-button {
    margin-left: 8px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 18px;
    color: rgba(255, 255, 255, 1);
    border-radius: 12px;
    padding: 9px 2px;
    border: none;
    width: 190px;
    background-color: rgba(16, 14, 113, 1);
    cursor: pointer;
}
.found-button:hover {
    margin-left: 8px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 18px;
    color: rgba(16, 14, 113, 1);
    border-radius: 12px;
    padding: 9px 2px;
    border: none;
    width: 190px;
    background-color: white;
    cursor: pointer;
}
.input-style {
    align-items: center;
    border: 1px none;
    width: 100%;
    height: 40px;
    padding: 6px 20px;
    border-radius: 24px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    color: rgba(16, 14, 113, 1);
    background-image: url("../../assets/lupa.png");
    background-repeat: no-repeat;
    background-position: 20px 12px;
    padding-left: 52px;
    background-size: 16px 16px;
}
.input-style::before {
    content: "";
    background-image: url("../../assets/icon-telefone.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
    margin-right: 8px;
}
::placeholder {
    color: rgba(16, 14, 113, 1);
}
.input {
    gap: 8px;
    display: flex;
    flex-direction: row;
    margin-top: 40px;
}
.button {
    border: none;
    width: 226px;
    color: #FFF;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    border-radius: 24px;
    background: #5FAD05;
    cursor: pointer;
}
.order-div {
    width: 100%;
    margin-top: 60px;
}
.modal-div {
    width: 100%;
}
.modal-title {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 45px;
    color: white;
}
.input-div {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.text-input {
    margin: 0;
    margin-bottom: -4px;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
}
.input-modal {
    border-radius: 8px;
    padding: 16px 24px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: rgba(102, 108, 114, 1);
}
.modal-button {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    gap: 12px;
}
.button-modal {
    width: 100%;
    padding: 16px 0;
    border-radius: 12px;
    border: 2px solid white;
    background-color: white;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: rgba(16, 14, 113, 1);
}
.button-modal:hover {
    background-color: rgba(16, 14, 113, 1);
    color: white;
}
@media (max-width: 768px) {
    .container {
        width: 100vw;
        margin: 0 auto;
    }
    .content {
        margin: 80px 12px 0;
    }
    .title {
        margin-top: 10vw;
        font-size: 7.5vw;
    }
    .title-text {
        font-weight: 600;
        font-size: 7.5vw;
        line-height: 55px;
    }
    .input-style {
        align-items: center;
        border: 1px none;
        width: 100%;
        height: 12.5vw;
        padding: 3.75vw 7.5vw;
        font-family: 'Inter', sans-serif;
        font-size: 5vw;
        color: rgba(16, 14, 113, 1);
        background-image: url("../../assets/lupa.png");
        background-repeat: no-repeat;
        background-position: 6.25vw 3.75vw;
        background-size: 16px 16px;
        padding-left: 13.75vw;
        position: relative;
    }
    ::placeholder {
        color: rgba(16, 14, 113, 1);
    }
    .input-style::before {
        content: "";
        background-image: url("../../assets/icon-telefone.png");
        background-repeat: no-repeat;
        background-size: contain;
        width: 7.5vw;
        height: 7.5vw;
        margin-right: 2.5vw;
    }
    .input {
        gap: 3.75vw;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 1.88vw;
    }
    .button-div {
        margin-top: 7.5vw;
    }
    .order-div {
        margin-top: 7.5vw;
    }
    .order-create-button {
        font-family: 'Roboto', sans-serif;
        font-size: 4.38vw;
        line-height: 5.63vw;
        color: rgba(255, 255, 255, 1);
        border-radius: 7.5vw;
        padding: 2.81vw 0.63vw;
        border: none;
        width: 59.38vw;
        background-color: rgba(95, 173, 5, 1);
    }
    .order-create {
        margin-top: 3.75vw;
        width: 94vw;
        display: flex;
        gap: 18px;
        flex-direction: row;
    }
    .button-filter {
        font-family: 'Inter', sans-serif;
        font-size: 3vw;
        line-height: 3.16vw;
        padding: 3.44vw 2.2vw;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .button-filter:hover {
        color: white;
        background-color: rgba(16, 14, 113, 1);
    }
    .found-button {
        align-items: center;
        align-self: center;
        margin-left: 2.5vw;
        font-family: 'Roboto', sans-serif;
        font-size: 4.38vw;
        line-height: 5.63vw;
        color: rgba(255, 255, 255, 1);
        border-radius: 7.5vw;
        padding: 2.81vw 0.63vw;
        border: none;
        width: 59.38vw;
        background-color: rgba(95, 173, 5, 1);
        cursor: pointer;
    }
}
