.my-profile-list {
    background-color: white;
    margin: 40px 53px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 12px;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.my-profile-title-mobile {
    display: none;
}
.button-div {
    display: flex;
    flex-direction: row;
    gap: 12px;
}
.div-button-china{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
}
.div-change-language{
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-left: 30px;
    margin-bottom: 5px;
}
.style-change-language{
    font-size: 16px;
    margin: 0;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;

}
.link-help{
    display: none;
}
@media screen and (max-width: 768px) {
    .container {
        position: fixed;
        top: 60px;
        left: 0;
        height: 100%;
        width: 100%;
        margin: 0px auto;
        padding-bottom: 100px;
        overflow-y: auto;
        ::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;
        -webkit-overflow-scrolling: touch;
    }

    .my-profile-list {
        width: 100%;
        margin: 36px 0;
    }
    .my-profile-title {
        display: none;
    }
    .my-profile-title-mobile {
        display: block;
    }
    .button-div{
        display: flex;
        flex-direction: column;
    }
    .content{
        margin: 0px 12px;
    }
    .link-help{
        display: block;
        font-size: 14px;
        font-family: 'Roboto', sans-serif;
        color: black;
        margin-right: 28px;
    }
    .my-profile-header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}
