.title-text{
    margin: 0;
    margin-top: 40px;
    color: #FFF;
    font-family: 'Roboto', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px; /* 125% */
}
/*.login-button-group{*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: center;*/
/*    align-items: flex-end;*/
/*    padding-bottom: 24px;*/
/*    height: auto;*/
/*}*/
.logo-div{
    display: none;
}
.register-input {
    padding: 12px 24px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    border-radius: 8px;
}
.register-input::placeholder {
    color: rgba(102, 108, 114, 1);
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}
.mapLogo{
    width: 100%;
    height: auto;
    top: 366px;
    gap: 0px;
    opacity: 0px;
}
.forgetPassword{
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    margin-top: -10px;
    color: rgba(255, 255, 255, 1);
}
.btn-style{
    margin-top: 8px;
    width: 100%;
    height: 52px;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #100E71;

}
.btn-style:hover{
    background-color: #100E71;
    color: white;
    border: 1px solid white;

}
.btn-style-reg{
    margin-top: 12px;
    width: 100%;
    height: 52px;
    background-color: #100E71;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: white;
    border: 1px solid white;
}
.btn-style-reg:hover{
    background-color: white;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #100E71;
    border: 1px solid #100E71;
}

@media (max-width: 768px) {
    .title-text{
        font-size: 28px;
        font-weight: 500;
        line-height: 30px;
        text-align: center;
    }
    .logo-div{
        display: block;
    }
}