.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}
.modal-container {
    display: flex;
    flex-direction: column;
}

.title {
    color: #100e71;
}

.search-wrap {
    display: flex;
    gap: 1rem;
    height: inherit;
    justify-content: space-between;
    align-items: center;
}

.primary-button {
    border: 1px none;
    border-radius: 12px;
    width: 170px;
    background-color: #100e71;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    padding: 6px 0;
}

.secondary-button {
    border: 1px solid #100e71;
    border-radius: 12px;
    width: 170px;
    background-color: #fff;
    color: #100e71;
    font-size: 16px;
    padding: 0.25rem;
    cursor: pointer;
    height: min-content;
}

.secondary-button:disabled {
    opacity: 0.3;
    color: #c4c3dc;
    cursor: not-allowed;
}

.continue-button {
    border: none;
    border-radius: 12px;
    background-color: #fff;
    color: #100e71;
    font-size: 16px;
    padding: 0.5rem;
    cursor: pointer;
    width: inherit;
}

.continue-button:disabled {
    opacity: 0.3;
    color: #c4c3dc;
    cursor: not-allowed;
}

.cancel-button {
    border: 1px solid #fff;
    border-radius: 12px;
    background-color: #100e71;
    color: #fff;
    font-size: 16px;
    padding: 0.5rem;
    cursor: pointer;
    width: inherit;
}

.change-password-button {
    border: 1px solid #fff;
    border-radius: 12px;
    background-color: #b1b1aa66;
    color: #100e71;
    font-size: 16px;
    padding: 0.5rem 2rem;
    cursor: pointer;
    width: inherit;
}

.input-style {
    align-items: center;
    border: 1px none;
    width: 100%;
    height: 40px;
    padding: 6px 20px;
    border-radius: 24px;
    font-family: Inter, sans-serif;
    font-size: 16px;
    color: rgba(16, 14, 113, 1);
    background-image: url('../../assets/lupa.png');
    background-repeat: no-repeat;
    background-position: 20px 14px;
    padding-left: 52px;
    background-size: 16px 16px;
}

.table-outside {
    border: 1px solid #100e71;
    padding: 1.5rem;
    border-radius: 8px;
    margin-top: 1rem;
    display: grid;
    overflow: auto;
}

.table-columns-wrap {
    display: flex;
    background-color: #fff;
}

.table-data {
    border-top: 2px solid #100e71;
    max-height: 100vh;
    display: inline-block;
    overflow: auto;
}

.table-data div:nth-child(odd) {
    background-color: #f3f4f8;
}

.table-cell {
    border: 0.5px solid #100e71;
    flex: 1;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-width: 100px;
    word-break: break-all;
}

.modal-buttons-wrap {
    display: flex;
    gap: 1rem;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
}

.buttons-wrap {
    display: flex;
    gap: 1rem;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.modal-title {
    font-size: 36px;
    line-height: 0;
}

.input-modal {
    border-radius: 8px;
    padding: 16px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: rgba(0, 0, 0, 0.88);
    width: 100%;
    border: none;
}

.input-modal::placeholder {
    color: rgba(0, 0, 0, 0.25);
}

.input-edit {
    border-radius: 8px;
    padding: 16px 24px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: rgba(102, 108, 114, 1);
    width: 100%;
    border: none;
    background-color: #f3f4f8;
    margin-bottom: 0.5rem;
}

.input-header {
    font-size: 20px;
    line-height: 0;
}

.form-wrap {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.width-inherit {
    width: inherit;
}

.with-top {
    margin-top: 1rem;
}

.line {
    height: 1rem;
    width: 3rem;
    color: #fff;
}

.edit-form {
    background-color: #fff;
    border-radius: 8px;
    width: 100%;
    padding: 24px;
}

.breadcrumps {
    cursor: pointer;
}

.edit-footer {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.success-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.error-msg {
    color: red;
}

.notification {
    background-color: #525184;
    color: #fff;
    border-radius: 6px;
    padding: 0.5rem;
}

@media (max-width: 768px) {
    .container {
        margin-top: 3rem;
    }
}
