@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

.container {
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
    top: 0;
    left: 0;
    width: 302px;
    height: 100%;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 1);
}
.container::-webkit-scrollbar {
    display: none;
}
.content-start {
    margin: 0;
}
.content-img {
    width: 97px;
    height: 97px;
    border-radius: 50%;
    margin-top: 42.5px;
}
.content-info {
    width: 100%;
    height: 279px;
    background-image: url("../../assets/logo-finish.jpg");
    -webkit-background-size: cover;
}
.content-div {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    align-self: center;
}
.content-start {
    margin-top: -8px;
    position: relative;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    padding: 5px 8px;
    background-color: rgba(242, 201, 76, 1);
    border-radius: 12px;
    font-family: 'Inter', sans-serif;
    color: white;
    display: flex;
    align-items: center;
    align-self: center;
    border: 2px solid rgba(27, 99, 207, 1);
}
.content-start::before {
    content: '';
    background-image: url('../../assets/star.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 11.92px;
    height: 11.39px;
    margin-right: 2px;
}
.content-name {
    margin: 0;
    margin-top: 5px;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    color: white;
    font-family: 'Inter', sans-serif;
}
.line {
    width: 260px;
    height: 1px;
    background-color: rgba(65, 125, 215, 1);
    margin-top: 24px;
}
.content-group {
    margin: 0;
    padding: 4px 8px;
    background-color: white;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 17.58px;
    margin-top: 24px;
    color: rgba(16, 14, 113, 1);
}
.type {
    gap: 12px;
    margin-top: 30px;
    height: auto;
    display: flex;
    flex-direction: column;
}
.type-div {
    gap: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 32px;
    border: none;
    background-color: white;
    color: rgba(89, 92, 102, 1);
    fill: rgba(89, 92, 102, 1);
    font-size: 18px;
    font-weight: 500;
    line-height: 21.78px;
    font-family: Inter, sans-serif;
    cursor: pointer;
    width: 85%;
    height: 38px;
    padding-left: 5px;
    border-radius: 8px;
    text-decoration: none;
}
.type-div:hover {
    color: rgba(27, 99, 207, 1);
    fill: rgba(27, 99, 207, 1);
    background-color: rgba(243, 244, 248, 1);
}

.type-div:active {
    color: rgba(27, 99, 207, 1);
    fill: rgba(27, 99, 207, 1);
    background-color: rgba(243, 244, 248, 1);
}
.type-div.active,
.type-div:focus {
    color: rgba(27, 99, 207, 1);
    fill: rgba(27, 99, 207, 1);
    background-color: rgba(243, 244, 248, 1);
}
.type-div:focus {
    color: rgba(27, 99, 207, 1);
    fill: rgba(27, 99, 207, 1);
    background-color: rgba(243, 244, 248, 1);
}
.div-change-language {
    display: flex;
    flex-direction: row;
    margin-left: 36px;
    gap: 8px;
    padding-bottom: 0px;
}

.logout-div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 40px;
    gap: 8px;
    padding-bottom: 100px;
}
.logout-text:hover {
    color: rgba(16, 14, 113, 1);
    background-color: white;
}
.logout-text {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.78px;
    text-align: center;
    color: rgba(237, 27, 36, 1);
}
.style-change-language {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.75px;
    text-align: left;
    color: rgba(177, 179, 182, 1);
}
.line-2 {
    margin-top: 100px;
    width: 250px;
    height: 1px;
    background-color: rgba(227, 227, 227, 1);
    margin-left: 31px;
}
.type-text {
    margin: 0;
}
.settings {
    gap: 12px;
    margin-top: 24px;
    height: 140px;
    display: flex;
    flex-direction: column;
}
.style-cube-svg {
    width: 24px;
    height: 24px;
}

@media (max-width: 768px) {
    .container {
        position: fixed;
        overflow-y: scroll; /* Разрешаем вертикальную прокрутку */
        overflow-x: hidden; /* Запрещаем горизонтальную прокрутку */
        scrollbar-width: none; /* IE and Edge */
        -ms-overflow-style: none; /* IE and Edge */
        top: 0;
        left: 0;
        width: 68.94vw;
        height: 100vh;
        margin: 0 auto;
        background-color: rgba(255, 255, 255, 1);
    }
    .container::-webkit-scrollbar {
        display: none;
    }
    .content-start {
        margin: 0;
    }
    .content-img {
        width: 22.8vw;
        height: 22.8vw;
        border-radius: 50%;
        margin-top: 9.991vw;
    }
    .content-info {
        width: 100%;
        height: 65.5781vw;
        background-color: rgba(16, 14, 113, 1);
        -webkit-background-size: cover;
    }
    .content-div {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        align-self: center;
        overflow-y: scroll;
        overflow-x: hidden;
        scrollbar-width: none;
    }
    .content-div::-webkit-scrollbar {
        display: none;
    }
    .content-start {
        margin-top: -1.04vw;
        position: relative;
        font-size: 1.56vw;
        font-weight: 500;
        line-height: 3.13vw;
        padding: 0.65vw 1.04vw;
        background-color: rgba(242, 201, 76, 1);
        border-radius: 1.56vw;
        font-family: 'Inter', sans-serif;
        color: white;
        display: flex;
        align-items: center;
        align-self: center;
        border: 0.26vw solid rgba(27, 99, 207, 1);
    }
    .content-start::before {
        content: '';
        background-image: url('../../assets/star.svg');
        background-repeat: no-repeat;
        background-size: contain;
        width: 1.55vw;
        height: 1.48vw;
        margin-right: 0.26vw;
    }
    .content-name {
        margin: 0;
        margin-top: 0.65vw;
        font-size: 2.08vw;
        font-weight: 600;
        line-height: 2.52vw;
        color: white;
        font-family: 'Inter', sans-serif;
    }
    .line {
        width: 33.85vw;
        height: 0.13vw;
        background-color: rgba(65, 125, 215, 1);
        margin-top: 3.13vw;
    }
    .content-group {
        margin: 0;
        padding: 0.52vw 1.04vw;
        background-color: white;
        border-radius: 1.04vw;
        font-family: 'Roboto', sans-serif;
        font-size: 1.95vw;
        font-weight: 400;
        line-height: 2.29vw;
        margin-top: 3.13vw;
        color: rgba(16, 14, 113, 1);
    }
    .type {
        gap: 10px;
        margin-top: 30px;
        height: auto;
        display: flex;
        flex-direction: column;
    }
    .type-div {
        gap: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 4.17vw;
        border: none;
        background-color: white;
        color: rgba(89, 92, 102, 1);
        fill: rgba(89, 92, 102, 1);
        font-size: 12px;
        font-weight: 500;
        line-height: 2.83vw;
        font-family: 'Roboto', sans-serif;
        cursor: pointer;
        width: 85%;
        height: auto;
        padding-left: 0.65vw;
        border-radius: 1.04vw;
        text-decoration: none;
    }

    .div-change-language {
        display: flex;
        flex-direction: row;
        margin-left: 4.69vw;
        margin-top: 10px;
        gap: 1.04vw;
    }
    /*.style-change-language {*/
    /*    margin: 0;*/
    /*    font-family: 'Roboto', sans-serif;*/
    /*    font-size: 2.08vw;*/
    /*    font-weight: 500;*/
    /*    line-height: 2.44vw;*/
    /*    text-align: left;*/
    /*    color: rgba(177, 179, 182, 1);*/
    /*}*/
    .style-change-language-x {
        margin: 0;
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        padding-bottom: 0.65vw;
        font-weight: 500;
        line-height: 2.44vw;
        text-align: left;
        color: rgba(177, 179, 182, 1);
    }
    .line-2 {
        margin-top: 26.04vw;
        width: 85%;
        height: 0.13vw;
        background-color: rgba(227, 227, 227, 1);
        margin-left: 4.04vw;
    }
    .type-text {
        margin: 0;
    }
    .settings {
        gap: 20px;
        margin-top: 3.13vw;
        height: 18.23vw;
        display: flex;
        flex-direction: column;
    }
    .style-cube-svg {
        width: 20px;
        height: 20px;
    }
}
