.container {
    display: flex;
    padding: 2rem;
    flex-direction: column;
}

.breadcrumps {
    display: none;
}

.title {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 44px;
    font-weight: 600;
    line-height: 55px;
    text-align: left;
    color: #100e71;
}

.table-outside {
    border: 1px solid #100e71;
    padding: 1.5rem;
    border-radius: 8px;
    margin-top: 60px;
    background-color: #fff;
}

.table-banner {
    border: 1px solid #100e71;
    padding: 1.5rem;
    border-radius: 8px;
    margin-top: 1rem;
    background-color: #fff;
}

.table-columns-wrap {
    background-color: #fff;
    display: grid;
    grid-template-columns: 30% 70%;
}

.table-columns-wrap-statuses {
    background-color: #fff;
    display: grid;
    grid-template-columns: 25% 75%;
}

.table-row {
    display: grid;
    grid-template-columns: 25% 75%;
    background-color: #fff;
}

.table-row:nth-child(odd) {
    background-color: #f3f4f8; /* Цвет для нечетных строк */
}
.table-data {
    border-top: 2px solid #100e71;
}
.table-header {
    border: 0.5px solid #100e71;
    flex: 1;
    font-family: 'Roboto', sans-serif;
    padding: 1rem;
    display: flex;
    justify-content: center;
    font-size: 14px;
}
.table-cell-icon {
    border: 0.5px solid #100e71;
    flex: 1;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    min-width: 100px;
    word-break: break-all;
    background: #f3f4f8;
}

.table-cell-number-icon {
    background-color: #100e71;
    color: #fff;
    border-radius: 12px;
    font-size: 40px;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.table-cell {
    border: 0.5px solid #100e71;
    flex: 1;
    padding: 0.5rem 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    text-align: left;
    min-width: 100px;
    word-break: break-all;
    background: #f3f4f8;
}

.table-cell-header {
    font-size: 20px;
    font-weight: 700;
}

.table-cell-li {
    padding-bottom: 0.5rem;
}

.table-cell-status {
    border: 0.5px solid #100e71;
    padding: 0.5rem 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.table-cell-text {
    font-family: 'Roboto', sans-serif;
    border: 0.5px solid #100e71;
    padding: 15.5px 5px;
    min-width: 100px;
}

.table-footer {
    border: 0.5px solid #100e71;
    flex: 1;
    padding: 0.5rem 1.5rem;
    background: #f3f4f8;
}

.table-cell-footer {
    font-size: 20px;
    line-height: 32px;
    background: #f3f4f8;
}

.aggreement-banner-title {
    font-size: 20px;
    line-height: 22px;
}

.aggreement-banner-text {
    font-size: 14px;
    line-height: 18px;
}

.grey {
    border: 1px solid #494b4f;
    background-color: #eaecf0;
    padding: 4px 16px 4px 16px;
    border-radius: 8px;
}

.blue {
    border: 1px solid #100e71;
    background-color: #cae0ff;
    padding: 4px 16px 4px 16px;
    border-radius: 8px;
}

.green {
    border: 1px solid #36590f;
    background-color: #e2fbc5;
    padding: 4px 16px 4px 16px;
    border-radius: 8px;
}

.orange {
    border: 1.05px solid #894705;
    background-color: #ffbd7b;
    padding: 4px 16px 4px 16px;
    border-radius: 8px;
}

.red {
    border: 1.05px solid #741f06;
    background-color: #fb8484;
    padding: 4px 16px 4px 16px;
    border-radius: 8px;
}

.input-style {
    margin-top: 60px;
    font-family: 'Roboto', sans-serif;
    align-items: center;
    border: 1px none;
    width: 100%;
    height: 40px;
    padding: 6px 20px;
    border-radius: 24px;
    font-family: Inter, sans-serif;
    font-size: 16px;
    color: rgba(16, 14, 113, 1);
    background-image: url('../../assets/lupa.png');
    background-repeat: no-repeat;
    background-position: 20px 14px;
    padding-left: 52px;
    background-size: 16px 16px;
}
@media (max-width: 768px) {
    .container {
        width: 100%;
        padding: 24px 12px 66px;
        margin-top: 0px;
    }
    .table-outside {
        border: none;
        padding: 0;
        margin-bottom: 12px;
    }
    .table-header {
        display: none;
    }
    .table-cell-icon {
        flex-direction: row;
        border-bottom: none;
    }
    .table-columns-wrap {
        display: contents;
    }
    .table-row {
        display: contents;
    }
    .table-cell {
        border-top: none;
    }
    .table-cell-status {
        border-bottom: none;
        padding: 1rem;
    }
    .table-cell-text {
        border-top: none;
        display: flex;
    }
    .aggreement-banner-title {
        font-size: 16px;
    }
    .aggreement-banner-text {
        font-size: 12px;
    }
    .title {
        font-size: 18px;
    }
    .table-cell-header {
        font-size: 16px;
    }
    .input-style{
        margin-top: 6px;
    }
    .table-outside {
        margin-top: 24px;
    }
    .table-data {
        border-top: 1px solid #100e71;

    }
    .breadcrumps {
        display: block;
        font-family:' Roboto', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        align-items: center;
        text-align: center;
        border-radius: 8px;
        height: 26px;
        width: 82px;
        margin: 0;
        color: white;
        background-color: #100e71;
        cursor: pointer;
    }
}
