.custom-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    /*overflow-x: auto;*/
    /*overflow-y: hidden;*/
}


.custom-modal {
    position: fixed;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(16, 14, 113, 1);
    padding: 40px;
    border-radius: 40px;
    z-index: 1000;
    max-width: 810px;
    width: 100%;
    box-sizing: border-box;
    color: white;
    /*overflow-x: scroll;*/
}
@media (max-width: 768px) {
    .custom-modal-backdrop {

        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999;
    }


    .custom-modal {
        overflow-x: auto;
        margin-top: 0px;
        position: fixed;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(16, 14, 113, 1);
        padding: 24px 16px;
        border-radius: 20px;
        z-index: 1000;
        max-width: 100%;
        width: 97%;
        box-sizing: border-box;
        color: white;
    }
}

