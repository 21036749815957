.order-report-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;

}

.order-report-card {
    background-color: white;
    padding: 20px;
    border-radius: 24px;
    width: fit-content;
    max-width: 100%;
    height: fit-content;
}

.order-report-header {
    display: flex;
    justify-content: start;
}

.order-report-title {
    margin: 0;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 29.05px;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 11px;
}

.order-report-stats {
    margin-top: 21px;
    display: flex;
    gap: 12px;
    align-items: start;
    flex-direction: column;

}

.order-report-all-count {
    margin: 0;
    color: #3b3c5a;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 58.09px;
    text-align: center;

}

.order-report-secondary-count {
    margin: 0;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 24.2px;
    text-align: left;

    color: #3b3c5a;
}

.order-report-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 10px;
}

.order-report-item {
    background-color: #f3f4f8;
    display: flex;
    gap: 8px;
    padding: 8px 16px;
    border-radius: 8px;
    align-items: center;
}

.order-report-value {
    margin: 0;
    font-family: 'Inter, sans-serif';
    font-size: 20px;
    color: #3b3c5a;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.2px;
    text-align: left;

}
.modal-div {
    width: 100%;
}
.modal-title {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 32px;
    font-weight: 400;
    line-height: 45px;
    color: white;
}
.order-report-label {
    margin: 0;
    font-family: 'Inter, sans-serif';
    font-size: 16px;
    font-weight: 500;
    color: #a1a2a7;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;

}
.floating-label {
    position: absolute;
    top: 50%;
    left: 24px;
    transform: translateY(-50%);
    transition: all 0.2s ease-in-out;
    color: rgba(102, 108, 114, 1);
    pointer-events: none;
    font-size: 16px;
    line-height: 24px;
}
.modal-error{
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: red;

}
.input-div {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.input-container {
    position: relative;
    margin-bottom: 0px;
    width: 100%;
}
.input-modal {
    width: 100%;
    border-radius: 8px;
    padding: 24px 24px 4px;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: rgba(16, 14, 113, 1);
    position: relative;
}
.input-modal:active + .floating-label:active,
.filled {
    padding: 0 0 35px;
    font-size: 16px;
    color: rgba(16, 14, 113, 1) ;
}
.input-error {
    border-color: red;
}
.error-message {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: red;
    margin: 0;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
}
.modal-button {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    gap: 12px;
}
.button-modal {
    width: 100%;
    padding: 10px 0;
    border-radius: 12px;
    border: 2px solid white;
    background-color: white;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: rgba(16, 14, 113, 1);
}

.button-modal:hover {
    background-color: rgba(16, 14, 113, 1);
    color: white;
}
.done-modal {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.userPlus{
    font-family: 'Roboto', sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 45px;
    text-align: center;
    margin: 0;
    margin-bottom: 72px;
}
.button-done {
    padding: 16px;
    width: 100%;
    border: none;
    border-radius: 12px;
    font-family: 'Roboto', sans-serif;
    color: rgba(16, 14, 113, 1);
    background-color: white;
    margin-top: 64px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
}
.button-done:hover {
    padding: 16px;
    width: 100%;
    border: none;
    border-radius: 12px;
    font-family: 'Roboto', sans-serif;
    color: white;
    background-color: rgba(16, 14, 113, 1);
    margin-top: 64px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
}
.button-text{
    color:  #FFF;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    background-color: rgba(16, 14, 113, 1);
    border-radius: 8px;
    border: none;
}
.order-report-secondary-div-count {
    display: flex;
    flex-direction: row;
    gap: 12px;
}
@media(max-width: 768px) {
    .order-report-grid {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .order-report-card {
        width: 100%;
    }
    .order-report-item{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .modal-title {
        margin: 0;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        color: white;
    }
    .input-div {

        margin-top: 6px;
        display: flex;
        flex-direction: column;
        gap: 6px;
    }
    .input-modal {
        border-radius: 8px;
        padding: 16px 24px 8px;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: rgba(102, 108, 114, 1);
    }
    .modal-div {
        width: 100%;
    }
    .input-modal:active + .floating-label:active,
    .filled {
        padding: 0 0 30px;
        font-size: 16px;
        color: rgba(16, 14, 113, 1) ;
    }
    .modal-button {
        display: flex;
        flex-direction: row;
        margin-top: 12px;
        gap: 12px;
    }
    .button-modal {
        width: 90%;
        padding: 8px 0;
        border-radius: 12px;
        border: 2px solid white;
        background-color: white;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: rgba(16, 14, 113, 1);
    }
    .done-modal {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .userPlus{
        font-family: 'Roboto', sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 45px;
        text-align: center;
        margin: 0;
        margin-bottom: 20px;
    }
    .button-done {
        padding: 5px;
        width: 100%;
        border: none;
        border-radius: 12px;
        font-family: 'Roboto', sans-serif;
        color: rgba(16, 14, 113, 1);
        background-color: white;
        margin-top: 22px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
    }
    .button-done:hover {
        padding: 16px;
        width: 100%;
        border: none;
        border-radius: 12px;
        font-family: 'Roboto', sans-serif;
        color: white;
        background-color: rgba(16, 14, 113, 1);
        margin-top: 64px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
    }
    .button-text{
        color:  #FFF;
        height: 46px;
        text-align: center;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        background-color: rgba(16, 14, 113, 1);
        border-radius: 8px;
        border: none;
    }
    .order-report-secondary-count {
        margin: 0;
        font-family: 'Inter', sans-serif;
        font-size: 20px;
        font-weight: 500;
        line-height: 24.2px;
        text-align: left;

        color: #3b3c5a;
    }
    .order-report-secondary-div-count {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
}
