.icon-container {
    background-color: #CAE0FF;
    margin-right: 15px;
    padding: 13px;
    display: flex;
    justify-items: center;
    border-radius: 10px;
    height: fit-content;
    width: fit-content;
}
@media(max-width: 768px) {
    .icon-container {
        margin-right: 10px;
        padding: 0; /* Убираем padding */
        display: flex;
        align-items: center;
        justify-content: center; /* Центрируем по горизонтали */
        border-radius: 8px;
        height: 35px;
        width: 35px;
    }
}