.container {
    display: flex;
    justify-content: center;
    padding: 2rem 6rem;
    flex-direction: column;
    gap: 3rem;
}

.card {
    background-color: #fff;
    border-radius: 24px;
    padding: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.link-wrapper {
    text-decoration: none; /* Убираем подчеркивание ссылки */
    color: inherit;        /* Сохраняем цвет текста в зависимости от темы */
    display: block;        /* Заставляем ссылку занимать всю ширину блока */
}

.card2 {
    background-color: #fff;
    border-radius: 8px;
    padding: 0.75rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.title {
    color: #100e71;
    font-size: 24px;
    margin: 0;
}

.row-wrap {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
    padding-top: 1rem;
}

.grid-wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    padding-top: 1rem;
    overflow-y: scroll;
}

.row-item-grey {
    background-color: #f3f4f8;
    border-radius: 24px;
    padding: 8px 12px 8px 12px;
    display: flex;
    gap: 14px;
    width: 100%;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    color: #000000;
    align-items: center;
}

.row-item {
    padding: 8px 12px 8px 12px;
    display: flex;
    gap: 14px;
    width: 100%;
}

.grey-text {
    color: #a1a2a7;
    font-size: 16px;
    font-weight: 500;
}

.blue-container {
    background-color: #cae0ff;
    border-radius: 8px;
    padding: 8px 12px 8px 12px;
    display: flex;
    gap: 14px;
    color: #100e71;
    font-weight: 700;
    width: max-content;
    margin-top: 1rem;
    cursor: pointer;
}

.center-container {
    display: flex;
    justify-content: center;
}
.promo-image {
    width: 100%;
}

.content{
    display: flex;
    padding: 12px;
    gap: 12px;
    border-radius: 24px;
    background: #FFF;

}
.title-block{
    margin: 0;
    width: 100%;
    text-align: end;
    color: #000;
    text-align: right;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 127.5%; /* 25.5px */
    letter-spacing: -0.2px;
}
.block{
    width: 100%;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    padding: 12px 41px 0 0 ;
    border: 2px solid rgba(201, 213, 225, 0.20);
    cursor: pointer;
}
.block-image{
    width: 114.849px;
    height: 114.849px;
    flex-shrink: 0;
}
.done-image{
    width: 320px;
    height: 120px;
    /*transform: rotate(-10.045deg);*/
    flex-shrink: 0;
}

.header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 24px;
    background: linear-gradient(90deg, #2a87d0, #1f5c9e, #1b396d);
    padding: 40px 39px;
    /*background: linear-gradient(0deg, rgba(255, 255, 255, 0.11) 0%, rgba(255, 255, 255, 0.11) 100%),  lightgray 50% / cover no-repeat;*/
}
.done-title {
    color: var(--Light-Text-White, #FFF);
    font-family: Roboto;
    font-size: 41.668px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 54.168px */
}
.done-text {
    margin: 0;
    color: #FABA03;
    font-family: Roboto;
    font-size: 32.408px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 42.131px */
}


@media (max-width: 968px) {
    .container {
        margin: 0px 0 50px 0;
        padding: 0.5rem 1rem 35px;
        gap: 12px
    }

    .row-wrap {
        flex-direction: column;
    }

    .grid-wrap {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        -webkit-overflow-scrolling: touch;
        scrollbar-width: none;
    }
    .row-item {
        padding: 8px 12px 8px 12px;
        display: flex;
        gap: 12px;
        width: 100%;
    }

    .grid-wrap::-webkit-scrollbar {
        display: none;
    }

    .card {
        flex: 0 0 60%;
        scroll-snap-align: start;
        transition: transform 0.3s ease-in-out;
    }

    .center-container {
        justify-content: center;
    }
    .grey-text {
        color: #a1a2a7;
        font-size: 14px;
        font-weight: 500;
        font-family: 'Roboto', sans-serif;
    }
    .card {
        background-color: #fff;
        border-radius: 24px;
        padding: 24px 18px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
    .title {
        color: #100e71;
        font-size: 20px;
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        margin: 0;
    }
    .row-item-grey {
        background-color: #f3f4f8;
        border-radius: 24px;
        padding: 8px 12px 8px 12px;
        display: flex;
        gap: 14px;
        font-family: 12px;
        width: 100%;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        color: #000000;
        align-items: center;
    }
    .container-report{
        margin-top: 0px;
    }
    .header {
        margin-top: 30px;
    }
    .content {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .block {
        padding: 6px 17px 0 0 ;
        flex: 1 1 calc(33.333% - 12px);
        width: calc(33.333% - 12px);
        border-radius: 12px;
        border: 2px solid #EDEDED;
        background: #F6F6F5;
    }
    .block:last-child {
        flex: 1 1 33%;
        width: 33%;
        margin-top: 0px;
    }
    .block-image{
        width: 68px;
        height: 68px;
        flex-shrink: 0;
    }
    .title-block{
        color: #000;
        text-align: right;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 127.5%; /* 17.85px */
        letter-spacing: -0.2px;
    }
    .done-title {
        width: 80%;
        color:  #FFF;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%; /* 23.4px */
        margin: 0;
    }
    .done-text {
        margin: 0;
        margin-top: 12px;
        color: #FABA03;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 18.2px */
    }
    .done-image{
        width: 150px;
        height: 50px;
        /*transform: rotate(-10.045deg);*/
        flex-shrink: 0;
    }

    .header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 24px;
        background: linear-gradient(90deg, #2a87d0, #1f5c9e, #1b396d);
        padding: 14px 17px;
        /*background: linear-gradient(0deg, rgba(255, 255, 255, 0.11) 0%, rgba(255, 255, 255, 0.11) 100%),  lightgray 50% / cover no-repeat;*/
    }
}

