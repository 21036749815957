.empty-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.text-style {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    color: rgba(16, 14, 113, 1);
    width: 312px;
    align-self: center;
    text-align: center;
}
@media (max-width: 768px) {
    .empty-container{
        width: 100%;
        background-color: white;
        padding: 16px;
    }
    .text-style {
        font-size: 14px;
        line-height: 27px;
        width: 278px;

    }
}
